import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { Button, Dialog, IconButton, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getUserCompanyId } from '../utils/auth';
import useFetch from '../hooks/useFetch';
import ValuationFileUpload from '../pages/CalcInputs/components/ValuationFileUpload';
import FileuploadModal from './FileuploadModal';
import './UploadValuationToProject.scss';


export default function UploadValuationToProject({
  uploadValuation,
  setUploadValuation,
  enterpriseCompanyIdent,
  investor,
}) {
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [addingCompanies, setAddingCompanies] = useState(false);
  const [blockHasError, setBlockHasError] = useState(false);
  const [companyNameErrorList, setCompanyNameErrorList] = useState([]);
  const [valuationFileupload, setvaluationFileupload] = useState([]);
  const [valuationDate, setvaluationDate] = useState(moment());
  const [disablesubmitbtn,setDisablesubmitbtn] =useState(true);
  const [filelocation,setFileocation]=useState('');
  const [Modalupload, setmodalupload] = useState(false);
  const [Valuationdatauploaded, setValuationdatauploaded] = useState(false);
  useEffect(()=>{
    if (valuationFileupload !== null && valuationDate !== null) {
      if (valuationFileupload.length !== 0 && valuationDate._isValid === true) {
        setDisablesubmitbtn(false);
      }
      else {
        setDisablesubmitbtn(true);
      }
    }
    else {
      setDisablesubmitbtn(true);
    }
  },
  [
    valuationDate,
    valuationFileupload,
  ],
  );
  function handlesnakbarClose() {
    setValuationdatauploaded(false);
  }
  return (
    <>
      <Dialog
        className="UploadValuationToProject"
        open={uploadValuation}
        disableScrollLock
      >
        <div className="add-companies-block">
          <div className="header-intro-wrapper">
            <UploadFileOutlinedIcon />
            <div className="header-text-wrapper">
              <h4>Upload valuation to this project</h4>
              <p>
                Upload a previous ASC 820 model here. We will upload the file, process it's contents, and start a new ASC 820 for the portfolio 
                company.
                We will preload the industry and comps list,
                capital structure, holdings, and basic info as well as mapping previous valuation data for analysis.
              </p>
            </div>
            <IconButton
              className="close-icon"
              onClick={() => {
                setUploadValuation(false);
                setProjectCompanies([]);
                setCompanyNameErrorList([]);
                setBlockHasError(false);
                setAddingCompanies(false);
                setDisablesubmitbtn(true);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="row w-100">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <ValuationFileUpload setvaluationFileupload={setvaluationFileupload}
                setDisablesubmitbtn={setDisablesubmitbtn}
                setFileocation ={setFileocation}
              />
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-self-center">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Valuation date*"
                  clearable
                  value={valuationDate} // Pass Moment object to the DatePicker
                  onChange={(newDate) => {
                    setvaluationDate(newDate); // Update state with the new date
                  }}
                  renderInput={(params) => <TextField {...params} className="date-picker" />}
                />
              </LocalizationProvider>
            </div>
            <hr />
            <div className="text-end col-12">
              <Button
                disabled={disablesubmitbtn}
                onClick={() => {
                  setmodalupload(true);
                  setUploadValuation(false);
                  setDisablesubmitbtn(true);
                }}
                className="add-company-btn  border rounded rounded-pill"
                sx={{
                  width: '200px',
                  height: '40px',
                  textTransform: 'capitalize !important',
                  ...(disablesubmitbtn ? {} : { borderColor: '#1976d2 !important' }),
                }}
              >
                <AddOutlinedIcon className="me-3" />
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <FileuploadModal modalupload={Modalupload} setmodalupload={setmodalupload}
        valuationDate={valuationDate} setvaluationDate={setvaluationDate}
        valuationFileupload={valuationFileupload} setvaluationFileupload={setvaluationFileupload}
        Valuationdatauploaded={Valuationdatauploaded} setValuationdatauploaded={setValuationdatauploaded}
        filelocation ={filelocation} setFileocation ={setFileocation}
      />
      <Snackbar
        open={Valuationdatauploaded}
        autoHideDuration={5000}
        anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
        onClose={handlesnakbarClose}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Valuation Data Uploaded
        </Alert>
      </Snackbar>
    </>
  );
}
