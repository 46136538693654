import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '@mui/material/Button';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


import TableElement from '../components/TableElement';
import BreakpointTable from './BreakpointTable';

import useFetch from '../../../hooks/useFetch';

import {
  defaultPreferredTableData,
  defaultSafeConvertibleTableData,
  defaultCommonTableData,
  defaultOptionsTableData,
  defaultWarrantsTableData,
  defaultRemainingOptionsPoolTableData,
  preferredTableSchema,
  createPreferredTable,
  safeConvertibleTableSchema,
  createSafeConvertibleTable,
  commonTableSchema,
  createCommonTable,
  optionsTableSchema,
  createOptionsTable,
  warrantsTableSchema,
  createWarrantsTable,
  remainingOptionPoolTableSchema,
  createRemainingOptionsPoolTable,
} from './tablesData';

import { allocateDBData, checkForErrorOnTable, convertToDBData } from './calcTablesUtils';

import {
  copy,
  removeCommas,
  commaEvery3rdChar,
} from '../../../utils';

import './index.scss';
import { AppDataContext, UserContext } from '../../../contexts';

export default function CapStructure() {
  // Tab1 Data
  const { userData, setUserData } = useContext(UserContext);
  const {exitingToTab, setExitingToTab, setTabSelected,saveCapData, setSaveCapData} =  useContext(AppDataContext)
  const [preferredTableData, setPreferredTableData] =
    useState([defaultPreferredTableData]);
  const [safeConvertibleTableData, setSafeConvertibleTableData] =
    useState([defaultSafeConvertibleTableData]);
  const [commonTableData, setCommonTableData] =
    useState([defaultCommonTableData]);
  const [optionsTableData, setOptionsTableData] =
    useState([defaultOptionsTableData]);
  const [warrantsTableData, setWarrantsTableData] =
    useState([defaultWarrantsTableData]);
  const [remainingOptionsPoolTableData, setRemainingOptionsPoolTableData] =
    useState([defaultRemainingOptionsPoolTableData]);

  const [capStructureSummary, setCapStructureSummary] = useState(null);

  const [showBreakpoints, setShowBreakpoints] = useState(false);

  // For handling loading states
  const [calcTableHasError, setCalcTableHasError] = useState(false);

  // Error Handling for Preferred and Safe Convertible
  const [inputsHaveError, setInputsHaveError] = useState([{}]);
  const [safeInputsHaveError, setSafeInputsHaveError] = useState([{}]);

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const changeInProgressRef = useRef(false);
  const changeHasBeenMadeRef = useRef(false);

  const [, updateCapTableRequest] = useFetch();
  const [, breakpointsRequest] = useFetch();

  // Format the data from the DB to the front-end format
  useEffect(() => {
    function assignRemainingOptionsPoolValues() {
      const newArray = [];
      const newObject = copy(defaultRemainingOptionsPoolTableData);
      newArray.push({
        ...newObject,
        available: {
          ...newObject.available,
          value: commaEvery3rdChar(userData.capData.remainingOptionsPool),
        },
      });
      return newArray;
    }
    if (userData.capData) {
      setPreferredTableData(userData.capData.preferred ?
        allocateDBData(userData.capData.preferred, preferredTableSchema, defaultPreferredTableData) :
        [defaultPreferredTableData]);
      setSafeConvertibleTableData(userData.capData.safeConvertible ?
        allocateDBData(
          userData.capData.safeConvertible,
          safeConvertibleTableSchema,
          defaultSafeConvertibleTableData,
        ) : [defaultSafeConvertibleTableData]);
      setCommonTableData(userData.capData.common ?
        allocateDBData(userData.capData.common, commonTableSchema, defaultCommonTableData, 'common') :
        [defaultCommonTableData]);

      setOptionsTableData(userData.capData.option ?
        allocateDBData(
          userData.capData.option,
          optionsTableSchema,
          defaultOptionsTableData,
          'options',
        ) :
        [defaultOptionsTableData]);
      setWarrantsTableData(userData.capData.warrant ?
        allocateDBData(
          userData.capData.warrant,
          warrantsTableSchema,
          defaultWarrantsTableData,
          'warrants',
        ) :
        [defaultWarrantsTableData]);
      setRemainingOptionsPoolTableData(
        userData.capData.remainingOptionsPool ?
          assignRemainingOptionsPoolValues() : [defaultRemainingOptionsPoolTableData],
      );
      setCapStructureSummary(userData.capData.summary ? userData.capData.summary : null);
    }
  }, [userData.capData]);

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  function parseDBData(autoSave) {
    let commonData = null;
    commonTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (row.outstanding.value.length !== 0 || autoSave) {
        title = `${row.common.value ? row.common.value : ''}`;
        values = {
          common: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          issuePrice: null,
          participationRights: null,
          prior: null,
        };
        if (!commonData) commonData = {};
        commonData = {
          ...commonData,
          ...title && { [title]: values },
        };
      }
    });

    // TODO  possibly have them be all in the one function in utils with different arguments
    let optionsData = null;
    optionsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (((row.outstanding.value.length !== 0) &&
        (row.strikePrice.value.length !== 0)) ||
        (row.options.value.length > 0)) {
        title = `${row.options.value ? row.options.value : ''} Option @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.options.type ? row.options.type : null,
          preferredClass: row.options.value ? row.options.value : null,
        };
        if (!optionsData) optionsData = {};
        optionsData = {
          ...optionsData,
          [title.trim()]: values,
        };
      }
    });
    let warrantsData = null;
    warrantsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (((row.outstanding.value.length !== 0) &&
        (row.strikePrice.value.length !== 0)) ||
        (row.warrants.value.length > 0)) {
        title = `${row.warrants.value ? row.warrants.value : ''} Warrant @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.warrants.type ? row.warrants.type : null,
          preferredClass: row.warrants.value ? row.warrants.value : null,
        };
        if (!warrantsData) warrantsData = {};
        warrantsData = {
          ...warrantsData,
          [title.trim()]: values,
        };
      }
    });
    const preferred = convertToDBData(preferredTableData);
    const safeConvertible = convertToDBData(safeConvertibleTableData);
    const newTableData = {
      ...userData.capData,
      capTableDate: userData?.transactionData?.basicInfo?.capTableDate,
      portfolioCompanyId: userData.metaData.portfolioCompanyId,
      preferred,
      safeConvertible,
      common: commonData,
      option: optionsData,
      warrant: warrantsData,
      remainingOptionsPool: remainingOptionsPoolTableData[0].available.value ?
        parseFloat(remainingOptionsPoolTableData[0].available.value.replaceAll(',', '')) : null,
    };
    return newTableData;
  }

  function saveData(autoSave = false, exitToTab = false) {
    if (!exitToTab && (!changeHasBeenMadeRef.current || changeInProgressRef.current)) {
      setSaveCapData(false);
      return;
    }
    const newTableData = parseDBData(autoSave);
    const preferredErrorCheck = checkForErrorOnTable(preferredTableData);
    const safeErrorCheck = checkForErrorOnTable(safeConvertibleTableData);
    if (safeErrorCheck.tableErrors.length && !autoSave) setSafeInputsHaveError(safeErrorCheck.tableErrors);
    if (preferredErrorCheck.tableErrors.length && !autoSave) setInputsHaveError(preferredErrorCheck.tableErrors);
    if (exitToTab || autoSave || (safeErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
      preferredErrorCheck.tableErrors.every((row) => !Object.keys(row).length) && !calcTableHasError)) {
      updateCapTableRequest({
        method: 'post',
        url: '/calc-engine/update-cap-structure-object',
        body: { ...newTableData, status: 'in progress' },
        bodyIds: ['requestUserId'],
        onSuccess: (responseData) => {
          setCapStructureSummary(responseData.summary ? responseData.summary : null);
          if (!autoSave) setUserData({ ...userData, capData: responseData });
          if (exitToTab) setTabSelected(exitToTab);
        },
        onFinally: () => {
          setChangeHasBeenMade(false);
          setSaveCapData(false);
        },
      });
    }
  }
  const [breakpointErrors, setBreakpointErrors] = useState([]);

  function camelToRegular(camelStr) {
    let regularStr = camelStr.replace(/([a-z])([A-Z])/g, '$1 $2');
    regularStr = regularStr.charAt(0).toUpperCase() + regularStr.slice(1).toLowerCase();
    return regularStr;
  }

  function runBreakpoints() {
    const breakpointsRequestData = {
      capStructure: parseDBData(),
      companyId: userData.metaData.portfolioCompanyId,
      transactionDate: userData.transactionData?.basicInfo?.transactionDate,
      valuationDate: userData.transactionData?.basicInfo?.valuationDate,
      ...userData.transactionData?.basicInfo?.exitTiming && {
        termYears: userData.transactionData?.basicInfo?.exitTiming.replace(/[^0-9]/g, ''),
      },
    };
    breakpointsRequest({
      url: '/calc-engine/generate_breakpoints',
      method: 'post',
      body: breakpointsRequestData,
      bodyIds: ['transactionId', 'requestUserId'],
      onSuccess: (responseData) => {
        const { breakpoints } = responseData;
        setUserData({ ...userData, breakpoints });
      },
      customError: true,
      onError: (res) => {
        const { detail } = res;
        detail?.forEach((error) => {
          const { loc } = error;
          let errorValue = loc[loc.length - 1];
          errorValue = camelToRegular(errorValue);
          if (!breakpointErrors.includes(errorValue)) {
            setBreakpointErrors((prevState) => [...prevState, errorValue]);
            setShowBreakpoints(!showBreakpoints);
          }
        });
      },
    });
  }

  useEffect(() => { if (saveCapData) { saveData(); } }, [saveCapData]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => { savedCallback.current = callback; }, [callback]);

    useEffect(() => {
      const func = () => { savedCallback.current(); };
      if (delay !== null) {
        const id = setInterval(func, delay);
        return () => clearInterval(id);
      }
      return null;
    }, [delay]);
  }

  useInterval(() => { saveData(true); }, 5000);

  useEffect(() => {
    if (exitingToTab) { saveData(false, exitingToTab); }
    setExitingToTab(false);
  }, [exitingToTab]);

  return (
    <main className="CapStructure">
      <TableElement
        tableData={preferredTableData}
        setTableData={setPreferredTableData}
        tableSchema={preferredTableSchema}
        createTable={createPreferredTable}
        canEditFirstColumn
        canAddOrDeleteRow
        isPreferredTable
        setCalcTableHasError={setCalcTableHasError}
        inputsHaveError={inputsHaveError}
        setInputsHaveError={setInputsHaveError}
        setChangeInProgress={setChangeInProgress}
        setChangeHasBeenMade={setChangeHasBeenMade}
      />
      <TableElement
        tableData={safeConvertibleTableData}
        setTableData={setSafeConvertibleTableData}
        tableSchema={safeConvertibleTableSchema}
        createTable={createSafeConvertibleTable}
        canEditFirstColumn
        canAddOrDeleteRow
        isSafeTable
        setCalcTableHasError={setCalcTableHasError}
        safeInputsHaveError={safeInputsHaveError}
        setSafeInputsHaveError={setSafeInputsHaveError}
        setChangeInProgress={setChangeInProgress}
        setChangeHasBeenMade={setChangeHasBeenMade}
      />
      <div className="inline-tables">
        <div>
          <TableElement
            tableData={commonTableData}
            setTableData={setCommonTableData}
            tableSchema={commonTableSchema}
            createTable={createCommonTable}
            canEditFirstColumn
            canAddOrDeleteRow
            isCommonTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <TableElement
            tableData={remainingOptionsPoolTableData}
            setTableData={setRemainingOptionsPoolTableData}
            tableSchema={remainingOptionPoolTableSchema}
            createTable={createRemainingOptionsPoolTable}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isRemainingOptionPoolTable
          />
        </div>
        <div>
          <TableElement
            tableData={optionsTableData}
            setTableData={setOptionsTableData}
            tableSchema={optionsTableSchema}
            createTable={createOptionsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isOptionTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <TableElement
            tableData={warrantsTableData}
            setTableData={setWarrantsTableData}
            tableSchema={warrantsTableSchema}
            createTable={createWarrantsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isWarrantTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
        </div>
      </div>
      <hr />
      <div className="cap-summary">
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalOutstandingShares).toFixed(0)) : '-'}</span>
          <p>Total Outstanding Shares</p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalOutstandingWithROP).toFixed(0)) : '-'}</span>
          <p>
            Total Outstanding Shares
            <br />
            + Remaining Options
          </p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalAsConvertedShares).toFixed(0)) : '-'}</span>
          <p>Total As Converted Shares</p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalAsConvertedSharesWithROP).toFixed(0)) : '-'}</span>
          <p>
            Total As Converted Shares
            <br />
            + Remaining Options
          </p>
        </div>
      </div>
      <hr />
      <div className={`breakpoint-analysis ${showBreakpoints ? 'active' : ''}`}>
        <Button
          onClick={() => {
            if (!showBreakpoints) runBreakpoints();
            setShowBreakpoints(!showBreakpoints);
          }}
        >
          {!showBreakpoints ? (
            <>
              <VisibilityOutlinedIcon />
              Show breakpoint analysis
            </>
          ) : (
            <>
              <VisibilityOffOutlinedIcon />
              Hide breakpoint analysis
            </>
          )}
        </Button>
        {showBreakpoints && (
          <div className="info-container">
            <div className="info">
              {
                userData?.transactionData?.basicInfo?.valuationDate ?
                  moment(userData?.transactionData?.basicInfo?.valuationDate).format('MM/DD/YYYY') :
                  '-'
              }
              <p>Valuation date</p>
            </div>
            <div className="info">
              {userData?.transactionData?.basicInfo?.exitTiming ? userData?.transactionData?.basicInfo?.exitTiming : '-'}
              <p>Term</p>
            </div>
            <p>
              Valuation date or Term not looking right?
              <br />
              Edit on &apos;
              <Button className="link-to-820inputs" onClick={() => setTabSelected('820-inputs')}>
                820 inputs
              </Button>
              &apos;
              tab. Term can be found under &apos;Most likely timing of exit event&apos;.
            </p>
            <Button className="refresh-btn" onClick={() => { runBreakpoints(); }}>
              Refresh breakpoint analysis table
            </Button>
          </div>
        )}
        {showBreakpoints ? (
          <div className="breakpoint-analysis backdrop">
            <div className="breakpoint-analysis content">
              <p>Breakpoint analysis</p>
              <BreakpointTable
                breakpointsData={userData?.breakpoints}
                setTabSelected={setTabSelected}
                breakpointErrors={breakpointErrors}
              />
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
}

// CapStructure.propTypes = {
//   userData: PropTypes.object.isRequired,
//   saveCapData: PropTypes.bool.isRequired,
//   setSaveCapData: PropTypes.func.isRequired,
//   setUserData: PropTypes.func.isRequired,
//   setTabSelected: PropTypes.func.isRequired,
//   exitingToTab: PropTypes.oneOfType([
//     PropTypes.bool,
//     PropTypes.string,
//   ]).isRequired,
//   setExitingToTab: PropTypes.func.isRequired,
// };
