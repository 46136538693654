import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from './App';

import ContextsProvider from './contexts/ContextsProvider';
import NavigationProvider from './contexts/NavigationProvider';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

// AWS Amplify configuration
// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#general-configuration
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
      secure: window.location.hostname !== 'localhost',
      path: '/',
      expires: 365,
    },
  },
  Storage: {
    AWSS3: {
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
});

// MUI Global Style Alterations
const muiGlobalTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        autoComplete: 'off',
      },
    },
  },
});

root.render(
  <>
    <Router>
      <NavigationProvider>
        <ContextsProvider>
          <ThemeProvider theme={muiGlobalTheme}>
            <App />
          </ThemeProvider>
        </ContextsProvider>
      </NavigationProvider>
    </Router>
  </>,
);
