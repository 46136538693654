import { createContext } from 'react';

export const UserContext = createContext();
export const IsSigningOutContext = createContext();
export const ErrorMessageContext = createContext();
export const SuccessModalContext = createContext();
export const NavWidthContext = createContext();
export const SubNavStateContext = createContext();
export const NavigationContext = createContext();
export const AppDataContext = createContext();

export default {
  UserContext,
  IsSigningOutContext,
  ErrorMessageContext,
  SuccessModalContext,
  NavWidthContext,
  SubNavStateContext,
  NavigationContext,
  AppDataContext
};
