import React, { useState, useEffect, useRef, useContext } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import { v4 as uuidv4 } from 'uuid';

import { commaEvery3rdChar, copy } from '../../utils';
import useFetch from '../../hooks/useFetch';

import './Holdings.scss';
import { AppDataContext, UserContext } from '../../contexts';

export default function Holdings() {
  const { userData, setUserData } = useContext(UserContext);
  const {exitingToTab, setExitingToTab, setTabSelected,} = useContext(AppDataContext);

  const [isEditing, setIsEditing] = useState(false);
  const [funds, setFunds] = useState([]);
  const [changeInProgress, setChangeInProgress] = useState(false);
  const [classNames, setClassNames] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [pageInfo, setPageInfo] = useState(false);
  const [noTableData, setNoTableData] = useState(false);
  const [capData, setCapData] = useState(true);
  const [savingEdits, setSavingEdits] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);
  const [originalTransactionData, setOriginalTransactionData] = useState({});
  const [fundNames, setFundNames] = useState([]);

  const changeHasBeenMadeRef = useRef(false);
  const changeInProgressRef = useRef(false);

  const [, updateTransactionRequest] = useFetch();
  const [, getFundListRequest] = useFetch();

  const emptyRow = {
    estimatedAsOfDate: null,
    sharesOwned: null,
    clientEstimatedValue: null,
    pricePerShare: null,
    classOfStock: null,
    carryingValue: null,
    fundName: null,
    rowId: uuidv4(),
  };

  function holdingOnChange(value, dataName, fundIdx, rIdx) {
    const newFundsData = [...funds];
    const parsedValue = parseFloat(value.replaceAll(',', '').replaceAll('$', ''));
    if (dataName === 'sharesOwned' && newFundsData[fundIdx].holdings[rIdx].pricePerShare) {
      const parsedPricePerShare = parseFloat(
        newFundsData[fundIdx].holdings[rIdx].pricePerShare.replaceAll(',', '').replaceAll('$', ''),
      );
      const newCarryingValue = commaEvery3rdChar((parsedValue * parsedPricePerShare).toFixed(4));
      newFundsData[fundIdx].holdings[rIdx].carryingValue = newCarryingValue;
    } else if (dataName === 'pricePerShare' && newFundsData[fundIdx].holdings[rIdx].sharesOwned) {
      const parsedSharesOwned = parseFloat(
        newFundsData[fundIdx].holdings[rIdx].sharesOwned.replaceAll(',', '').replaceAll('$', ''),
      );
      const pricePerShare = `$${commaEvery3rdChar(parsedValue.toFixed(4))}`;
      newFundsData[fundIdx].holdings[rIdx].pricePerShare = pricePerShare;
      const newCarryingValue = `$${commaEvery3rdChar(
        (parsedValue * parsedSharesOwned).toFixed(4),
      )}`;
      newFundsData[fundIdx].holdings[rIdx].carryingValue = newCarryingValue;
    } else if (dataName === 'classOfStock') {
      // This will have to be updated if the class of stock is repeated in the cap structure tab
      const safe = userData.capData.safeConvertible ? userData.capData.safeConvertible : {};
      const preferred = userData.capData.preferred ? userData.capData.preferred : {};
      const common = userData.capData.common ? userData.capData.common : {};
      const classes = { ...safe, ...preferred, ...common };
      newFundsData[fundIdx].holdings[rIdx].pricePerShare = classes[value]
        ? `$${commaEvery3rdChar(parseFloat(classes[value].issuePrice).toFixed(4))}`
        : null;
    }
    newFundsData[fundIdx].holdings[rIdx] = {
      ...newFundsData[fundIdx].holdings[rIdx],
      [dataName]: ['carryingValue', 'pricePerShare'].includes(dataName)
        ? `$${commaEvery3rdChar(value)}`
        : dataName === 'sharesOwned'
        ? commaEvery3rdChar(value)
        : value,
    };
    setFunds(newFundsData);
  }

  function setHoldingsData() {
    setFunds(() => {
      let holdingsData = userData.transactionData.holdings || [];
      if (holdingsData) {
        setOriginalTransactionData(userData.transactionData);
        const fundNameSet = new Set(holdingsData.map((v) => v.fundName));
        const fundNameArray = Array.from(fundNameSet);
        const groupedFunds = fundNameArray.map((fundName) => ({
          fundName,
          fundId: uuidv4(),
          holdings: holdingsData.filter((val) => val.fundName === fundName),
        }));
        const mappedFunds = groupedFunds.map((fund) => {
          const fundCopy = copy(fund);
          fundCopy.holdings = fundCopy.holdings.map((holding) => {
            const holdingCopy = { ...holding };
            holdingCopy.rowId = uuidv4();
            holdingCopy.sharesOwned = holdingCopy.sharesOwned
              ? commaEvery3rdChar(holdingCopy.sharesOwned)
              : null;
            holdingCopy.pricePerShare = holdingCopy.pricePerShare
              ? `$${commaEvery3rdChar(parseFloat(holdingCopy.pricePerShare).toFixed(4))}`
              : null;
            holdingCopy.carryingValue = holdingCopy.carryingValue
              ? `$${commaEvery3rdChar(parseFloat(holdingCopy.carryingValue).toFixed(4))}`
              : null;
            return holdingCopy;
          });
          return fundCopy;
        });
        setFundNames(fundNameArray);
        holdingsData = mappedFunds;
      }
      return holdingsData;
    });
  }
  useEffect(() => {
    setHoldingsData();
  }, [userData]);

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  function removeUnusedRows() {
    setFunds((prevFunds) => {
      const fundsCopy = copy(prevFunds);
      fundsCopy.forEach((fund) => {
        const fundCopy = copy(fund);
        const holdingsInFund = fundCopy.holdings;
        if (holdingsInFund.every((val) => !val.classOfStock)) {
          fundsCopy.splice(fundsCopy.indexOf(fund), 1);
        } else if (holdingsInFund.some((val) => !val.classOfStock)) {
          const newFundHoldings = holdingsInFund.filter((val) => val.classOfStock);
          fundsCopy[fundsCopy.indexOf(fund)].holdings = newFundHoldings;
        }
      });
      return fundsCopy;
    });
  }

  function saveData(autoSave, exitingTab) {
    if (autoSave && (!changeHasBeenMadeRef.current || changeInProgressRef.current)) return;
    if (!autoSave) setSavingEdits(true);
    let fundsToSave = copy(funds);
    fundsToSave.forEach((fund) => {
      const fundCopy = copy(fund);
      if (fundCopy.holdings.every((val) => !val.classOfStock)) {
        fundsToSave.splice(fundsToSave.indexOf(fund), 1);
      } else if (fundCopy.holdings.some((val) => !val.classOfStock)) {
        const newFundHoldings = fundCopy.holdings.filter((val) => val.classOfStock);
        fundsToSave[fundsToSave.indexOf(fund)] = newFundHoldings;
      }
    });
    fundsToSave = fundsToSave.flatMap((fund) => fund.holdings);
    fundsToSave = fundsToSave.map((holding) => {
      const holdingCopy = { ...holding };
      holdingCopy.sharesOwned = holdingCopy.sharesOwned
        ? parseFloat(holdingCopy.sharesOwned.replaceAll(',', ''))
        : null;
      holdingCopy.pricePerShare = holdingCopy.pricePerShare
        ? parseFloat(holdingCopy.pricePerShare.replaceAll('$', '').replaceAll(',', ''))
        : null;
      holdingCopy.carryingValue = holdingCopy.carryingValue
        ? parseFloat(holdingCopy.carryingValue.replaceAll('$', '').replaceAll(',', ''))
        : null;
      return holdingCopy;
    });
    const transactionDBData = {
      ...userData.transactionData,
      holdings: fundsToSave,
      version: parseInt(userData.transactionData.version, 10),
    };
    updateTransactionRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: transactionDBData,
      bodyIds: ['projectId', 'requestUserId'],
      onSuccess: () => {
        if (!autoSave) setUserData({ ...userData, transactionData: transactionDBData });
      },
      onFinally: () => {
        setSavingEdits(false);
        setChangeHasBeenMade(false);
        if (!autoSave) setIsEditing(false);
        if (exitingTab) setTabSelected(exitingToTab);
      },
    });
  }

  // This is to get the class names from the cap structure tab
  const getClassNames = () => {
    const classes = [];
    if (userData.capData.safeConvertible)
      classes.push(...Object.keys(userData.capData.safeConvertible));
    if (userData.capData.preferred) classes.push(...Object.keys(userData.capData.preferred));
    if (userData.capData.common) classes.push(...Object.keys(userData.capData.common));
    return classes;
  };

  // This is to get the fund list from the database
  function getFundList() {
    getFundListRequest({
      url: '/companies/get-funds-list/',
      urlIds: ['enterpriseCompanyId', 'investorCompanyId', 'requestUserId'],
      onSuccess: (res) => {
        const response = res.filter((fundName) => {
          if (!userData?.transactionData?.holdings) return true;
          return !userData?.transactionData?.holdings.find(
            (holding) => holding.fundName === fundName,
          );
        });
        setFundNames(response);
      },
    });
  }

  useEffect(() => {
    setClassNames(getClassNames());
    getFundList();
  }, [userData]);

  useEffect(() => {
    if (userData.capData) {
      if (
        !userData.capData.preferred &&
        !userData.capData.common &&
        !userData.capData.safeConvertible
      )
        setCapData(false);
      else if (funds.length === 0) setNoTableData(false);
      else if (funds.length > 0) {
        setNoTableData(true);
        setShowTable(true);
      }
    }
  }, [userData, funds]);

  useEffect(() => {
    if (exitingToTab && isEditing) saveData(false, true);
    else if (exitingToTab) setTabSelected(exitingToTab);
    setExitingToTab(false);
  }, [exitingToTab]);

  const getEvenOrOdd = (rowIndex, fundIdx, isExtraRow = false) => {
    const holdingsArray = funds.map((fund) => fund.holdings);
    let evenOrOdd = '';
    if (fundIdx === 0) {
      evenOrOdd = rowIndex % 2 === 0 ? ' even' : ' odd';
    } else {
      const rowsBefore = holdingsArray.slice(0, fundIdx).reduce((acc, val) => acc + val.length, 0);
      const totalRows = isExtraRow ? rowsBefore + 1 : rowsBefore + rowIndex;
      evenOrOdd = totalRows % 2 === 0 ? ' even' : ' odd';
    }
    return evenOrOdd;
  };

  function fundNameOnChange(value, fundIdx, reason, fundId, rowIdx) {
    const fundsCopy = copy(funds);
    const { fundName } = funds[fundIdx];
    if (!fundNames.includes(fundName) && reason === 'clear' && fundName.length) {
      setFundNames([...fundNames, fundName]);
      fundsCopy[fundIdx].fundName = value;
    } else if (!value && reason === 'clear') {
      fundsCopy[fundIdx].fundName = value;
    } else if (value.length !== 0 && fundName.length && value.length) {
      fundsCopy[fundIdx].fundName = value;
    } else if (reason === 'reset') {
      fundsCopy[fundIdx].fundName = value;
      const fundNamesCopy = copy(fundNames);
      fundNamesCopy.splice(fundNamesCopy.indexOf(fundName), 1);
      setFundNames(fundNamesCopy);
    } else if (reason === 'input') {
      fundsCopy[fundIdx].fundName = value;
    }
    fundsCopy[fundIdx].holdings[rowIdx].fundName = value;
    setFunds(fundsCopy);
  }

  function fundNameOnBlur(value, fundIdx, fundId, rowIdx) {
    const fundsCopy = copy(funds);
    const fundExists = funds.some(
      (fund) => fund.fundName === value && fund.fundId !== fundId && fund.fundName !== '',
    );
    fundsCopy[fundIdx].nameError = fundExists;
    fundsCopy[fundIdx].holdings[rowIdx].fundName = fundExists ? '' : value;
    setFunds(fundsCopy);
  }

  return (
    <div className='Holdings'>
      <div className={`page-info ${pageInfo ? 'active' : ''}`}>
        <p>
          <LightbulbOutlinedIcon />
          Not seeing the class of stock you&apos;re looking for? You&apos;ll need to add it to the
          Cap structure tab first to be able to select it here.
        </p>
        <IconButton onClick={() => setPageInfo(!pageInfo)}>
          <CloseOutlinedIcon />
        </IconButton>
      </div>
      <div className='input-block'>
        <div className='block-header'>
          <h5>Holdings</h5>
        </div>
        <div className='holdings-titles'>
          <span>Fund name</span>
          <span>Class of stock</span>
          <span>Shares owned</span>
          <span>Price per share</span>
          <span>Carrying Value</span>
        </div>
        {!capData ? (
          <div className='holdings-data no-cap-data even'>
            <p>No holdings have been added</p>
            <p>
              You will be able to add Holdings once Stock Classes have been entered on the Cap
              Structure tab.
            </p>
          </div>
        ) : !noTableData ? (
          <div className='holdings-data no-holdings even'>
            <p>No holdings have been added</p>
          </div>
        ) : (
          showTable && (
            <>
              {funds.map((fund, fundIndex) => (
                <React.Fragment key={fund.fundId}>
                  <div className='holdings-data-container'>
                    {fund.holdings.map((data, rowIndex) => (
                      <React.Fragment key={data.rowId}>
                        <div
                          className={`holdings-data${
                            fund.nameError ? ' fund-error' : ''
                          }${getEvenOrOdd(rowIndex, fundIndex)}`}
                        >
                          {!isEditing ? (
                            <>
                              <span className='holding-data'>
                                {rowIndex === 0 ? data.fundName : ' '}
                              </span>
                              <span className='holding-data'>{data.classOfStock || '-'}</span>
                              <span className='holding-data'>{data.sharesOwned || '-'}</span>
                              <span className='holding-data'>
                                {data.pricePerShare ? data.pricePerShare : '-'}
                              </span>
                              <span className='holding-data'>
                                {data.carryingValue ? data.carryingValue : '-'}
                              </span>
                            </>
                          ) : (
                            <>
                              {[
                                'fundName',
                                'classOfStock',
                                'sharesOwned',
                                'pricePerShare',
                                'carryingValue',
                              ].map((dataName, columnIdx) => (
                                <React.Fragment key={dataName}>
                                  {!data.fundName && columnIdx !== 0 ? (
                                    <span className='holding-data'>-</span>
                                  ) : columnIdx === 0 && rowIndex === 0 ? (
                                    <Autocomplete
                                      freeSolo
                                      value={funds[fundIndex].holdings[rowIndex]?.fundName || ''}
                                      options={fundNames}
                                      onInputChange={(event, newValue, reason) =>
                                        fundNameOnChange(
                                          newValue,
                                          fundIndex,
                                          reason,
                                          fund.fundId,
                                          rowIndex,
                                        )
                                      }
                                      onBlur={(e) =>
                                        fundNameOnBlur(
                                          e.target.value,
                                          fundIndex,
                                          fund.fundId,
                                          rowIndex,
                                        )
                                      }
                                      onFocus={() => {
                                        setChangeInProgress(true);
                                        setFunds((prevFunds) => {
                                          const newFunds = [...prevFunds];
                                          newFunds[fundIndex].nameError = false;
                                          return newFunds;
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={funds[fundIndex][rowIndex]?.fundName || ''}
                                          error={fund.nameError}
                                          helperText={
                                            fund.nameError ? (
                                              <>
                                                <ErrorIcon />
                                                Duplicate fund names not accepted
                                              </>
                                            ) : (
                                              ' '
                                            )
                                          }
                                        />
                                      )}
                                    />
                                  ) : columnIdx === 1 ? (
                                    <TextField
                                      select={columnIdx === 1}
                                      key={dataName}
                                      value={data[dataName] || ''}
                                      onChange={(e) =>
                                        holdingOnChange(
                                          e.target.value,
                                          dataName,
                                          fundIndex,
                                          rowIndex,
                                        )
                                      }
                                      onFocus={() => setChangeInProgress(true)}
                                      SelectProps={{
                                        MenuProps: {
                                          disableScrollLock: true,
                                          classes: { paper: 'select-dropdown' },
                                        },
                                      }}
                                    >
                                      {classNames.map((className, idx) => (
                                        <MenuItem key={`${idx + 1}`} value={className}>
                                          {className}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  ) : columnIdx > 1 && data.classOfStock ? (
                                    <TextField
                                      select={columnIdx === 1}
                                      key={dataName}
                                      value={data[dataName] || ''}
                                      onChange={(e) =>
                                        holdingOnChange(
                                          e.target.value,
                                          dataName,
                                          fundIndex,
                                          rowIndex,
                                        )
                                      }
                                      onFocus={() => setChangeInProgress(true)}
                                      onBlur={() => {
                                        setChangeInProgress(false);
                                        setTransactionData({ ...transactionData, funds });
                                      }}
                                    />
                                  ) : (
                                    <div
                                      style={
                                        columnIdx === 0 ? { display: 'flex', width: '280px' } : null
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                              <IconButton
                                onClick={() => {
                                  if (funds.length === 0) {
                                    setFunds([emptyRow]);
                                    setIsEditing(false);
                                  } else if (
                                    rowIndex === 0 &&
                                    funds[fundIndex].holdings.length === 1
                                  ) {
                                    setFunds((newFundData) => {
                                      const fundsCopy = [...newFundData];
                                      const { fundName } = fundsCopy[fundIndex];
                                      setFundNames((prevFundNames) => [...prevFundNames, fundName]);
                                      fundsCopy.splice(fundIndex, 1);
                                      return fundsCopy;
                                    });
                                  } else if (data.classOfStock) {
                                    const newFundData = [...funds];
                                    newFundData[fundIndex].holdings.splice(rowIndex, 1);
                                    setFunds(newFundData);
                                    setTransactionData((prevTransactionData) => ({
                                      ...prevTransactionData,
                                      holdings: newFundData,
                                    }));
                                  }
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </>
                          )}
                        </div>
                        {isEditing &&
                          fund.holdings.length - 1 === rowIndex &&
                          fund.holdings[fund.holdings.length - 1].classOfStock && (
                            <div
                              className={`holdings-data${getEvenOrOdd(rowIndex, fundIndex, true)}`}
                            >
                              <Button
                                className='add-holding-btn'
                                onClick={() => {
                                  setFunds((prevFunds) => {
                                    const fundsCopy = copy(prevFunds);
                                    const lastFund = fundsCopy[fundIndex];
                                    const lastHolding =
                                      lastFund.holdings[lastFund.holdings.length - 1];
                                    if (lastHolding.classOfStock)
                                      fundsCopy[fundIndex].holdings.push({
                                        ...emptyRow,
                                        fundName: lastFund.fundName,
                                      });
                                    return fundsCopy;
                                  });
                                  setIsEditing(true);
                                }}
                              >
                                <AddIcon />
                                Add Holding
                              </Button>
                            </div>
                          )}
                      </React.Fragment>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </>
          )
        )}
        {capData && (
          <div
            className={`holdings-data${
              funds ? (funds.flat().length % 2 === 0 ? ' even' : ' odd') : ''
            }`}
          >
            <Button
              className={`add-fund-btn ${classNames.length === 0 ? 'not-visible' : ''}`}
              onClick={() => {
                setFunds((prevFunds) => {
                  const fundsCopy = copy(prevFunds);
                  fundsCopy.push({ fundName: '', fundId: uuidv4(), holdings: [{ ...emptyRow }] });
                  return fundsCopy;
                });
                setIsEditing(true);
              }}
            >
              <AddIcon />
              Add Fund
            </Button>
          </div>
        )}
        <div
          className={`footer-container${
            funds.length
              ? (funds.map((fund) => fund.holdings).flat().length + 1) % 2 === 0
                ? ' even'
                : ' odd'
              : ''
          }`}
        >
          {!isEditing ? (
            <Button
              className={`edit-btn ${!capData || !noTableData ? 'not-visible' : ''}`}
              onClick={() => {
                setOriginalTransactionData(userData.transactionData);
                setIsEditing(true);
              }}
            >
              <EditIcon />
              Edit
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  setUserData({ ...userData, transactionData: originalTransactionData });
                  removeUnusedRows();
                  setIsEditing(false);
                  setChangeHasBeenMade(true);
                }}
              >
                <CancelOutlinedIcon />
                Cancel
              </Button>
              <Button
                onClick={() => {
                  removeUnusedRows();
                  saveData();
                }}
              >
                {savingEdits ? (
                  <>
                    <span className='dots-circle-spinner' />
                    Saving
                  </>
                ) : (
                  <>
                    <SaveOutlinedIcon />
                    Save
                  </>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
