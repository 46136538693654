import React, {
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { Storage } from 'aws-amplify';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ErrorMessageContext } from '../../../contexts';
import './UploadTile.scss';

export default function ValuationFileUpload({ setvaluationFileupload,
  setDisablesubmitbtn,
  setFileocation,
}) {
  const [fileUploading, setFileUploading] = useState(false);
  const [fileValue, setFileValue] = useState('');
  const [uploadfile, setUploadfile]= useState(null);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);
  const validFileTypes = ['.xlsm'];
  const uploadEl = useRef(null);
  const dragAndDropAreaRef = useRef(null);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const uploadFileToS3 = async (file) => {
    const envLabel = process.env.REACT_APP_ENV_LABEL; // e.g., 'prod', 'staging'
    const fileName = file.name;
    const location = `/redwood-models/${fileName}`;
    const bucket = `${process.env.REACT_APP_UPLOADS_BUCKET}-${envLabel}`;
    try {
      await Storage.put(location, file, {
        customPrefix: { public: 'ModelUploads' },
        bucket,
        progressCallback(progress) {
          setUploadProgress({ total: progress.total, loaded: progress.loaded });
        },
      });
      setFileUploading(false);
      const fullLocation = `s3://${bucket}/ModelUploads${location}`;
      setFileocation(fullLocation);
      setvaluationFileupload(file);
      setDisablesubmitbtn(false);
    } catch (error) {
      setShowErrorMessage('Error uploading file');
      setFileUploading(false);
    }
  };
  async function handleFileUpload(event, filesToUpload) {
    event.preventDefault();
    const file = filesToUpload[0];
    setFileUploading(true);
    setUploadfile(file.name);
    if (file && file.size >= 35000000) {
      setShowErrorMessage('File size is too big');
      setDisablesubmitbtn(true);
    }
    uploadFileToS3(file);
  }
  const removefile=()=>{
    setvaluationFileupload(null);
    setUploadfile(null);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
    handleFileUpload(e, e.dataTransfer.files);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
  };

  const dragAndDropArea = useCallback((node) => {
    if (dragAndDropAreaRef?.current) {
      dragAndDropAreaRef.current.removeEventListener('dragover', handleDragOver);
      dragAndDropAreaRef.current.removeEventListener('drop', handleDrop);
      dragAndDropAreaRef.current.removeEventListener('dragenter', handleDragEnter);
      dragAndDropAreaRef.current.removeEventListener('dragleave', handleDragLeave);
    }
    if (node) {
      node.addEventListener('dragover', handleDragOver);
      node.addEventListener('drop', handleDrop);
      node.addEventListener('dragenter', handleDragEnter);
      node.addEventListener('dragleave', handleDragLeave);
    }
    dragAndDropAreaRef.current = node;
  }, []);
  return (
    <div className="UploadTile">
      <div className={`drag-and-drop-area${fileIsDraggingOver ? ' file-dragging' : ''}`}
        ref={dragAndDropArea}
      >
        {fileUploading && (
        <div className="file-upload-loading-wrapper">
          <span className="dots-circle-spinner" />
        </div>
        )}
        <div className="browse-or-drop">
          <span>Drop files here</span>
          <span>or</span>
          <Button onClick={() => {
            setFileValue('');
            uploadEl.current.click(); }}
          >
            Browse
          </Button>
        </div>
        <input
          ref={uploadEl}
          type="file"
          accept={validFileTypes.join(',')}
          value={fileValue}
          className="file-style"
          onChange={(e) => handleFileUpload(e, e.target.files)}
        />
      </div>
      {uploadfile ? (
        <div className="file-and-progress">
          <div className="file-name">
            {fileUploading ?(
              <div className="dots-circle-spinner" />
            ) :(
              <>

                {(uploadfile.length >= 30) ? `${uploadfile.slice(0, 30).trim()}...` : uploadfile}
                <IconButton onClick={()=>{
                  removefile();
                }}
                  className="clear-icon"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </>
            )}
          </div>
        </div>
      ):(<div />)}
    </div>
  );
}
