import React, { useState, useEffect, useContext } from 'react';
import Alert from '@mui/material/Alert';
import { ErrorMessageContext } from '../contexts';

export default function AlertDisplay() {
  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!showErrorMessage); // Open the alert if showErrorMessage exists
  }, [showErrorMessage]);



  return (
    open && (
      <Alert
        severity="error"
        style={{
          position: 'fixed',
          top: '20px',
          left: '25%',
          right: '25%',
          zIndex: 2000,
        }}
        onClose={() => {
          setShowErrorMessage('');
          setOpen(false);
        }}
      >
        {showErrorMessage}
      </Alert>
    )
  );
}
