import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import AddInvestorClients from './AddInvestorClients';
import StartASC820 from './StartASC820';
import Success from './Success';

import { copy, stripDenomination } from '../../utils';

import { getUserName } from '../../utils/auth';

import useFetch from '../../hooks/useFetch';

import './index.scss';
import { AppDataContext } from '../../contexts';

export default function ASC820Dialog({
  startASC820,
  setStartASC820,
  portfolioCompanies,
  emailOnly,
  projectCompaniesData,
}) {
  const dialogScrollRef = useRef(null);
  const companyListRef = useRef(null);
  const clientListRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const {investorCmpList ,investorCmpNames, enterpriseProjects, setEnterpriseProjects, } = useContext(AppDataContext);
  
  const [companyRowAdded, setCompanyRowAdded] = useState(false);
  const [investorRowAdded, setInvestorRowAdded] = useState(false);

  const [currentStep, setCurrentStep] = useState(emailOnly ? 'add-investor' : 'start-asc-820');

  const [sendEmailForm, setSendEmailForm] = useState('true');

  const [clients, setClients] = useState([
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  ]);

  const [clientInputsHaveErrors, setClientInputsHaveError] = useState([
    {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
    },
  ]);

  const [inputsHaveErrors, setInputsHaveErrors] = useState({
    investorFirmName: false,
    projectName: false,
    clientInfoDueDate: false,
  });

  const [ASC820Info, setASC820Info] = useState({});

  const [projectCompanies, setProjectCompanies] = useState([]);

  const [companyNameErrorList, setCompanyNameErrorList] = useState([]);
  const [selectedInvestorName, setSelectedInvestorName] = useState('');
  const [investorOptions, setInvestorOptions] = useState([]);
  // const [capIqFromDB, setCapIqFromDB] = useState(false);
  // TODO implement when design is finalized
  // const [investorUsersList, setInvestorUsersList] = useState({});

  const [emailCallData, setEmailCallData] = useState([]);

  const [{ loading: creating820, success: create820Success }, setStart820Request] = useFetch();
  const [{ loading: addingInvestorUser, success: addInvestorSuccess }, setAddInvestorUsersRequest] = useFetch();
  const [{ loading: sendingEmail, success: sendEmailSuccess }, sendEmailRequest] = useFetch();


  function clearSetters(setters) {
    setters.forEach(([value, setter]) => {
      const type = typeof value;
      let emptyValue;
      switch (type) {
        case 'boolean':
          emptyValue = false;
          break;
        case 'object':
          if (Array.isArray(value)) emptyValue = [];
          else emptyValue = {};
          break;
        case 'string':
          emptyValue = '';
          break;
        case 'number':
          emptyValue = 0;
          break;
        default:
          emptyValue = '';
      }
      setter(emptyValue);
    });
  }

  function handleDataClear() {
    const setters = [
      [startASC820, setStartASC820],
      [projectCompanies, setProjectCompanies],
      [inputValue, setInputValue],
      [companyNameErrorList, setCompanyNameErrorList],
      [clientInputsHaveErrors, setClientInputsHaveError],
      [inputsHaveErrors, setInputsHaveErrors],
      [ASC820Info, setASC820Info],
    ];
    clearSetters(setters);
    setClients([{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }]);
    setCurrentStep(emailOnly ? 'add-investor' : 'start-asc-820');
    setSendEmailForm('true');
  }

  function createASC820() {
    const userName = getUserName();
    const usersFirstLastName = userName.split(' ');
    function sendInvestorEmails(investorCompanyId) {
      setEmailCallData(clients.map((client) => ({
        recipient: client.email,
        templateName: 'newASC820Project',
        templateData: [
          emailOnly ? projectCompaniesData.investorFirmName : ASC820Info.investorFirmName,
          encodeURIComponent(client.firstName),
          usersFirstLastName[0],
          usersFirstLastName[1],
          // TODO need endpoint to get account information from API
          'Redwood Valuations',
          encodeURIComponent(client.email),
          encodeURIComponent(client.lastName),
          encodeURIComponent(client.phone),
          encodeURIComponent(investorCompanyId),
        ],
      })));
    }
    if (!emailOnly) {
      const updatedCompanies = projectCompanies.map((company) => {
        const companyCopy = { ...company };
        if (portfolioCompanies[companyCopy.companyName]) {
          companyCopy.companyId = portfolioCompanies[companyCopy.companyName].companyId;
          companyCopy.capIqIdentifier = portfolioCompanies[companyCopy.companyName].capIqIdentifier;
        } else {
          companyCopy.companyId = '';
        }
        return companyCopy;
      });
      const newASC820Data = {
        ...ASC820Info,
        clients,
        enterpriseOwned: 0,
        companies: updatedCompanies,
      };
      if (newASC820Data.totalFee) newASC820Data.totalFee = stripDenomination(newASC820Data.totalFee);
      setStart820Request({
        url: '/projects/asc820/start-820-valuation', method: 'post',
        body: newASC820Data, bodyIds: ['enterpriseCompanyId', 'requestUserId'],
        onSuccess: (responseData) => {
          const { projectCard } = responseData;
          const enterpriseProjectsCopy = copy(enterpriseProjects);
          enterpriseProjectsCopy.push(projectCard);
          setEnterpriseProjects(enterpriseProjectsCopy);
          if (sendEmailForm === 'true') sendInvestorEmails(responseData.investorCompany.companyId);
        },
      });
    } else {
      const newInvestorsData = {
        investorCompanyId: projectCompaniesData.investorCompanyId,
        clients,
      };
      setAddInvestorUsersRequest({
        url: '/projects/asc820/new-investor-users', method: 'post',
        body: newInvestorsData, bodyIds: ['enterpriseCompanyId', 'requestUserId'],
      });
      if (sendEmailForm === 'true') sendInvestorEmails(projectCompaniesData.investorCompanyId);
    }
  }

  useEffect(() => {
    if (emailCallData.length) {
      sendEmailRequest({
        url: '/email/send-html-email', method: 'post',
        body: emailCallData[emailCallData.length - 1],
        multipleCalls: emailCallData.length,
        onFinally: () => setEmailCallData(emailCallData.slice(0, -1)),
      });
    }
  }, [emailCallData]);

  useEffect(() => {
    if ((create820Success || addInvestorSuccess) && (sendEmailSuccess || !(sendEmailForm === 'true'))) setCurrentStep('success-dialog');
  }, [create820Success, addInvestorSuccess, sendEmailSuccess]);

  // TODO implement later when design is finalized

  // async function getInvestorUsers() {
  //   const invCoId = ASC820Info.investorCompanyId;
  //   let investorUsersResponse = await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/accounts/get-investor-users/${enterpriseCompanyId}&${invCoId}&${requestUserId}`,
  //     await createFetchHeaders('get', {}, true),
  //   );
  //   if (investorUsersResponse.status === 200) {
  //     investorUsersResponse = await investorUsersResponse.json();
  //     // setInvestorUsersList(investorUsersResponse);
  //   }
  // }

  useEffect(() => {
    if (companyRowAdded) {
      setTimeout(() => {
        if (dialogScrollRef.current) {
          dialogScrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
      setTimeout(() => {
        if (companyListRef.current) {
          companyListRef.current.scrollIntoView({ behavior: 'smooth' });
          companyListRef.current.focus();
        }
      }, 200);
    }
    setCompanyRowAdded(false);
  }, [companyRowAdded]);

  useEffect(() => {
    if (investorRowAdded) {
      setTimeout(() => {
        if (clientListRef.current) {
          clientListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
    setInvestorRowAdded(false);
  }, [investorRowAdded]);

  useEffect(() => {
    const asc820Copy = copy(ASC820Info);
    if (selectedInvestorName.length) {
      const id = investorCmpList[selectedInvestorName]?.companyId;
      if (id) {
        const capIq = investorCmpList[selectedInvestorName]?.capIqIdentifier;
        if (capIq !== null) {
          asc820Copy.investorCapIqIdentifier = capIq;
          // setCapIqFromDB(true);
        } else {
          asc820Copy.investorCapIqIdentifier = null;
          // setCapIqFromDB(false)
        }
        asc820Copy.investorCompanyId = id;
        setASC820Info(asc820Copy);
        // getInvestorUsers();
      }
    }
    setASC820Info(asc820Copy);
  }, [selectedInvestorName]);

  useEffect(() => {
    if (investorCmpNames.length > 0) {
      const options = investorCmpNames.map((option) => {
        const firstLetter = option[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          option,
        };
      });
      setInvestorOptions(options);
    }
  }, [investorCmpNames]);

  if (!startASC820) return null;

  return (
    <Dialog className={currentStep} open={startASC820} disableScrollLock>
      <div className="dialog-content">
        <IconButton className="close-icon" onClick={() => handleDataClear()}>
          <CloseIcon />
        </IconButton>
        {currentStep === 'start-asc-820' && (
          <StartASC820
            ASC820Info={ASC820Info}
            setASC820Info={setASC820Info}
            handleDataClear={() => handleDataClear()}
            inputsHaveErrors={inputsHaveErrors}
            setInputsHaveErrors={setInputsHaveErrors}
            investorOptions={investorOptions}
            setSelectedInvestorName={setSelectedInvestorName}
            
            projectCompanies={projectCompanies}
            setProjectCompanies={setProjectCompanies}
            companyNameErrorList={companyNameErrorList}
            setCompanyNameErrorList={setCompanyNameErrorList}
            portfolioCompanies={portfolioCompanies}
         
            companyListRef={companyListRef}
            dialogScrollRef={dialogScrollRef}
            setCurrentStep={setCurrentStep}
            setCompanyRowAdded={setCompanyRowAdded}
          />
        )}
        {currentStep === 'add-investor' && (
          <AddInvestorClients
            clients={clients}
            setClients={setClients}
            sendEmailForm={sendEmailForm}
            setSendEmailForm={setSendEmailForm}
            clientInputsHaveErrors={clientInputsHaveErrors}
            setClientInputsHaveError={setClientInputsHaveError}
            clientListRef={clientListRef}
            dialogScrollRef={dialogScrollRef}
            createASC820InProgress={creating820 || addingInvestorUser || sendingEmail}
            createASC820={() => createASC820()}
            setCurrentStep={setCurrentStep}
            emailOnly={emailOnly}
            setInvestorRowAdded={setInvestorRowAdded}
          />
        )}
        {currentStep === 'success-dialog' && <Success sendEmailForm={sendEmailForm} primaryClientInfo={clients[0]} emailOnly={emailOnly} />}
      </div>
    </Dialog>
  );
}

ASC820Dialog.propTypes = {
  startASC820: PropTypes.bool.isRequired,
  setStartASC820: PropTypes.func.isRequired,
  setEnterpriseProjects: PropTypes.func,
  enterpriseProjects: PropTypes.arrayOf(PropTypes.object),
  enterpriseUsers: PropTypes.array,
  investorCompanies: PropTypes.object,
  portfolioCompanies: PropTypes.object,
  investorCompaniesNames: PropTypes.array,
  portfolioCompaniesNames: PropTypes.array,
  emailOnly: PropTypes.bool,
  projectCompaniesData: PropTypes.object,
};

ASC820Dialog.defaultProps = {
  setEnterpriseProjects: () => { },
  enterpriseProjects: [],
  enterpriseUsers: [],
  investorCompanies: {},
  investorCompaniesNames: [],
  portfolioCompanies: {},
  portfolioCompaniesNames: [],
  emailOnly: false,
  projectCompaniesData: {},
};
