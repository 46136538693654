import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { Button } from '@mui/material';

import ASC820Dialog from '../../components/ASC820Dialog';

import accountSuccess from '../../images/account_success.svg';

import './SplashPage.scss';


export default function SplashPage() {
  const [startASC820, setStartASC820] = useState(false);

  return (
    <>
      <div className="SplashPage">
        <div className="splash-container">
          <img src={accountSuccess} alt="account success" />
          <p>
            Success! You&apos;re all set up.
            <br />
            Your ASC 820 valuations will show up here. Start your first whenever you&apos;re ready.
          </p>
          <Button
            onClick={() => setStartASC820(true)}
          >
            Start an ASC 820 valuation
          </Button>
        </div>
      </div>
      <ASC820Dialog
        startASC820={startASC820}
        setStartASC820={setStartASC820}
      />
    </>
  );
}

SplashPage.propTypes = {
  setEnterpriseProjects: PropTypes.func.isRequired,
  enterpriseUsers: PropTypes.arrayOf(PropTypes.object),
  enterpriseProjects: PropTypes.arrayOf(PropTypes.object),
  investorCompaniesList: PropTypes.object,
  portfolioCompaniesList: PropTypes.object,
  investorCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  portfolioCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  setPortfolioCompaniesNames: PropTypes.func.isRequired,
};

SplashPage.defaultProps = {
  enterpriseUsers: [],
  enterpriseProjects: [],
  investorCompaniesList: [],
  portfolioCompaniesList: [],
  investorCompaniesNames: [],
  portfolioCompaniesNames: [],
};
