import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  UserContext,
  IsSigningOutContext,
  ErrorMessageContext,
  NavWidthContext,
  SubNavStateContext,
  AppDataContext,
} from '.';

export default function ContextsProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [navWidth, setNavWidth] = useState(null);
  const [subNavState, setSubNavState] = useState({});
  const [investorCmpList, setInvestorCmpList] = useState(null);
  const [investorCmpNames, setInvestorCmpNames] = useState(null);
  const [enterpriseProjects, setEnterpriseProjects] = useState(null);
  const [portfolioCompaniesList, setPortfolioCompaniesList] = useState(null);
  const [portfolioCompaniesNames, setPortfolioCompaniesNames] = useState(null);
  const [enterpriseUsers, setEnterpriseUsers] = useState(null);
  const [projectToView, setProjectToView] = useState(null);
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [transactionToView, setTransactionToView] = useState(null);
  const [investorCompanyDetailToView, setInvestorCompanyDetailToView] = useState(null);
  const [currentInvestorId, setCurrentInvestorId] = useState("")
  const [completedProjects, setCompletedProjects] = useState([]);
  const [tabSelected, setTabSelected] = useState(null);
  const [checkForMissingCompInputs, setCheckForMissingCompInputs] = useState(false);
  const [exitingToTab, setExitingToTab] = useState(false);
  const [saveCapData, setSaveCapData] = useState(false);
  const [runningComps, setRunningComps] = useState(false);
  const [compSuccess, setCompSuccess] = useState(false);
  const [selectedGridValue, setSelectedGridValue] = useState({ term: '', volatility: '' });
  const [generateNewReport, setGenerateNewReport] = useState({ type: null });


  const userDataProviderValue = useMemo(() => ({ userData, setUserData }), [userData]);
  const isSigningOutProviderValue = useMemo(
    () => ({ isSigningOut, setIsSigningOut }),
    [isSigningOut],
  );
  const showErrorMessageProviderValue = useMemo(
    () => ({ showErrorMessage, setShowErrorMessage }),
    [showErrorMessage],
  );
  const navWidthProviderValue = useMemo(() => ({ navWidth, setNavWidth }), [navWidth]);
  const subNavStateProviderValue = useMemo(() => ({ subNavState, setSubNavState }), [subNavState]);
  const AppDataContextValue = useMemo(
    () => ({
      investorCmpList,
      setInvestorCmpList,
      investorCmpNames,
      setInvestorCmpNames,
      enterpriseProjects,
      setEnterpriseProjects,
      portfolioCompaniesList,
      setPortfolioCompaniesList,
      portfolioCompaniesNames,
      setPortfolioCompaniesNames,
      enterpriseUsers, setEnterpriseUsers,
      projectToView, setProjectToView,
      projectCompanies, setProjectCompanies,
      transactionToView, setTransactionToView,
      investorCompanyDetailToView, setInvestorCompanyDetailToView,
      currentInvestorId, setCurrentInvestorId,
      completedProjects, setCompletedProjects,
      tabSelected, setTabSelected,
      checkForMissingCompInputs, setCheckForMissingCompInputs,
      exitingToTab, setExitingToTab,
      saveCapData, setSaveCapData,
      runningComps, setRunningComps,
      compSuccess, setCompSuccess,
      selectedGridValue, setSelectedGridValue,
      generateNewReport, setGenerateNewReport
      
    }),
    [
      investorCmpList,
      investorCmpNames,
      enterpriseProjects,
      portfolioCompaniesList,
      portfolioCompaniesNames,
      enterpriseUsers,
      projectToView,
      projectCompanies,
      transactionToView,
      investorCompanyDetailToView, 
      currentInvestorId, 
      completedProjects, 
      tabSelected,
      checkForMissingCompInputs, 
      exitingToTab, 
      saveCapData,
      runningComps, 
      compSuccess, 
      selectedGridValue, 
      generateNewReport, 
    ],
  );

  return (
    <UserContext.Provider value={userDataProviderValue}>
      <IsSigningOutContext.Provider value={isSigningOutProviderValue}>
        <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
          <NavWidthContext.Provider value={navWidthProviderValue}>
            <SubNavStateContext.Provider value={subNavStateProviderValue}>
              <AppDataContext.Provider value={AppDataContextValue}>
                {children}
              </AppDataContext.Provider>
            </SubNavStateContext.Provider>
          </NavWidthContext.Provider>
        </ErrorMessageContext.Provider>
      </IsSigningOutContext.Provider>
    </UserContext.Provider>
  );
}

ContextsProvider.propTypes = { children: PropTypes.element.isRequired };
