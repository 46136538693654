import React, { useState, useContext } from 'react';

import useFetch from '../../hooks/useFetch';

import EnterpriseProjects from '../EnterpriseProjects';
import SplashPage from './SplashPage';

import './index.scss';
import { AppDataContext } from '../../contexts';

export default function Home() {
  const {
    investorCmpList,
    setInvestorCmpList,
    setInvestorCmpNames,
    investorCmpNames,
    enterpriseProjects,
    setEnterpriseProjects,
    portfolioCompaniesList,
    setPortfolioCompaniesList,
    portfolioCompaniesNames,
    setPortfolioCompaniesNames,
    enterpriseUsers,
    setEnterpriseUsers,
  } = useContext(AppDataContext);

  useFetch({
    url: '/homepages/enterprise/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: setEnterpriseProjects,
  });

  useFetch({
    url: '/accounts/get-enterprise-users/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: setEnterpriseUsers,
  });

  useFetch({
    url: '/companies/get_investor_and_portfolio_companies/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: (responseData) => {
      setPortfolioCompaniesList(responseData.portfolioCompanies);
      setPortfolioCompaniesNames(Object.keys(responseData.portfolioCompanies));
      setInvestorCmpNames(Object.keys(responseData.investorCompanies));
      setInvestorCmpList(responseData.investorCompanies);
    },
  });

  if (
    !enterpriseProjects ||
    !enterpriseUsers ||
    !investorCmpList ||
    !portfolioCompaniesList ||
    !investorCmpNames ||
    !portfolioCompaniesNames
  ) {
    return (
      <div className='data-loading-spinner'>
        <div className='dots-circle-spinner' />
      </div>
    );
  }

  if (enterpriseProjects.length > 0) {
    return <EnterpriseProjects />;
  }

  return <SplashPage />;
}
