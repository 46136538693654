import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function EquityValue({ reportData, exhibitNumber }) {
  const tableData = reportData.EquityValueConclusionSummary;

  const tableHeaders = [
    'Valuation approach',
    ...tableData.backsolve ? [tableData.backsolve['Valuation approach']] : [],
    ...tableData.pubco ? [tableData.pubco['Valuation approach']] : [],
    ...tableData.mna ? [tableData.mna['Valuation approach']] : [],
  ];

  const rowNames = [
    'Enterprise value, minority, marketable',
    'Plus: cash and equivalents',
    'Less: debt',
    'Equity value',
  ];

  const firstColumnWidth = () => {
    if (tableHeaders.length === 4) return '560px';
    if (tableHeaders.length === 3) return '740px';
    return '920px';
  };

  function returnTableData(columnName, rowName) {
    let goalSeekEqValue;
    if (tableData.goalSeek) {
      goalSeekEqValue =tableData.goalSeek['Enterprise value, minority, marketable']; }
    else if (tableData.backsolve) {
      goalSeekEqValue =tableData.backsolve['Enterprise value, minority, marketable'];
    }
    const currentMethod = Object.keys(tableData).find((method) => tableData[method]['Valuation approach'] === columnName);
    if (columnName === 'Backsolve Method - Option Pricing Model') {
      if (rowName === 'Equity value') {
        return `$${commaEvery3rdChar(goalSeekEqValue.toFixed(2))}`;
      }
      return '-';
    }
    if (columnName === 'Guideline Public Company Method') {
      if (rowName === 'Enterprise value, minority, marketable') {
        return `$${commaEvery3rdChar(tableData[currentMethod][rowName])}`;
      }
      if (rowName === 'Weighting') return `${tableData[currentMethod][rowName]}%`;
      if (rowName === 'Plus: cash and equivalents') {
        return currentMethod !== 'pubco' ? 'N/A' : tableData[currentMethod]['Plus: accrued interest, cash, and equivalents'] ?
          `$${commaEvery3rdChar(tableData[currentMethod]['Plus: accrued interest, cash, and equivalents'])}` : '-';
      }
    }
    return '-';
  }

  function adjustedEquityValue(columnName) {
    let currentMethod;
    if (columnName !== 'Guideline Public Company Method') {
      currentMethod = Object.keys(tableData).find((method) => tableData[method]['Valuation approach'] === columnName);
      return `$${commaEvery3rdChar((tableData[currentMethod]['Equity value'].toFixed(2)))}`;
    }
    return '';
  }

  const footnote = reportData.footnoteData.eqValueConclusion;

  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Equity Value Conclusion & Summary   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          marginTop: '84px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
          border: '1px',
          borderColor: '#AFB1B3',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '56px',
            backgroundColor: '#0C2617',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#8AB9A4',
              marginTop: 'auto',
              paddingBottom: '8px',
              paddingLeft: '16px',
              letterSpacing: '0.08px',
              width: '740px',
            }}
          >
            (USD$)
          </Text>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#E1E2EC',
              marginTop: 'auto',
              paddingBottom: '8px',
              paddingLeft: '16px',
              paddingRight: '16px',
              letterSpacing: '0.08px',
              width: '290px',
              textAlign: 'right',
            }}
          >
            Market
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '56px',
            backgroundColor: '#307157',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {tableHeaders.map((dataName, index) => (
            <Text
              key={dataName}
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#E1E2EC',
                width: index === 0 ? firstColumnWidth() : '180px',
                marginTop: 'auto',
                paddingBottom: '4px',
                paddingLeft: '16px',
                paddingRight: '16px',
                letterSpacing: '0.5px',
                textAlign: index !== 0 && 'right',
              }}
            >
              {dataName}
            </Text>
          ))}
        </View>
        {rowNames.map((rowName, rowIndex) => (
          <View
            key={rowName}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '32px',
              backgroundColor: rowIndex % 2 === 0 ? '#F0F0F3' : '#FAFAFD',
              ...rowIndex === 3 && { borderBottom: '1px', borderTop: '1px', borderColor: '#999BA5' },
            }}
          >
            {tableHeaders.map((dataName, cellIndex) => cellIndex === 0 ? (
              <Text
                key={dataName}
                style={{
                  fontFamily: rowIndex === 3 ? 'RobotoMed' : 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: firstColumnWidth(),
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {rowName}
              </Text>
            ) : (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '180px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.5px',
                  textAlign: 'right',
                }}
              >
                {returnTableData(dataName, rowName)}
              </Text>
            ))}
          </View>
        ))}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '32px',
            backgroundColor: '#FAFAFD',
          }}
        >
          {tableHeaders.map((dataName, columnIndex) => (
            <Text
              key={dataName}
              style={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                color: '#49454F',
                width: columnIndex === 0 ? firstColumnWidth() : '180px',
                paddingLeft: '16px',
                ...columnIndex !== 0 && { paddingRight: '16px', textAlign: 'right' },
                letterSpacing: '0.5px',
              }}
            >
              {columnIndex === 0 ?
                'Market equity adjustment' :
                dataName === 'Backsolve Method - Option Pricing Model' ?
                  `${tableData.marketAdjustment.toFixed(2)}%` : ''}
            </Text>
          ))}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '32px',
            backgroundColor: '#9ECEBB',
          }}
        >
          {tableHeaders.map((dataName, columnIndex) => (
            <Text
              key={dataName}
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: columnIndex === 0 ? firstColumnWidth() : '180px',
                paddingLeft: '16px',
                ...columnIndex !== 0 && { paddingRight: '16px', textAlign: 'right' },
                letterSpacing: '0.5px',
              }}
            >
              {columnIndex === 0 ? 'Adjusted equity value' : adjustedEquityValue(dataName, columnIndex)}
            </Text>
          ))}
        </View>
      </View>
      {!!footnote && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            color: '#49454F',
            letterSpacing: '0.4px',
            marginTop: '16px',
            marginLeft: '90px',
            width: '450px',
            lineHeight: '0.55mm',
          }}
        >
          Footnote:&nbsp;
          {footnote}
        </Text>
      )}
      <BottomPagination
        pageNumber={5}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

EquityValue.propTypes = {
  reportData: PropTypes.object.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
