import React, { useState, useEffect, useContext, useRef, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import isEqual from 'lodash.isequal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import SelectAllIcon from '@mui/icons-material/SelectAll';
// import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import FilterMenus from './FilterMenus';
import CompTable from './CompTable/CompTable';
import SummariesTable from './CompTable/SummariesTable';
import EquityInputs from './EquityInputs';
import AddCompModal from './AddCompModal';
import RefreshCompsModal from './RefreshCompsModal';

import { getUserId } from '../../../utils/auth';

import { AppDataContext, ErrorMessageContext, UserContext } from '../../../contexts';

import { copy } from '../../../utils';

import useFetch from '../../../hooks/useFetch';
import useS3 from '../../../hooks/useS3';

import './index.scss';

export default function Comps() {
  const { userData, setUserData } = useContext(UserContext);
  const {
    setCheckForMissingCompInputs,
    tabSelected,
    setTabSelected,
    runningComps,
    setRunningComps,
    setCompSuccess,
    setSelectedGridValue,
  } = useContext(AppDataContext);
  const [noCompsToShow, setNoCompsToShow] = useState(false);
  const [identifyingOutliersSuccess, setIdentifyingOutliersSuccess] = useState(false);

  const [compData, setCompData] = useState([]);
  const [summariesData, setSummariesData] = useState([]);
  const [transactionData, setTransactionData] = useState(userData.transactionData || {});

  const [currentSummarySelections, setCurrentSummarySelections] = useState({});
  const [summariesOutdated, setSummariesOutdated] = useState(false);
  const [reSummarizeComplete, setReSummarizeComplete] = useState(false);

  const [compsColumns, setCompsColumns] = useState([]);

  const [industries, setIndustries] = useState(['']);
  const [searchIndustryInput, setSearchIndustryInput] = useState(['']);
  const [currentIndustries, setCurrentIndustries] = useState(['']);

  const [showAddCompModal, setShowAddCompModal] = useState(false);
  const [compsToAdd, setCompsToAdd] = useState([{ rowId: 0, compInput: '' }]);

  const [showRefreshCompsModal, setShowRefreshCompsModal] = useState(false);

  const [showIndustriesModal, setShowIndustriesModal] = useState(false);

  const [descriptionsToShow, setDescriptionsToShow] = useState([]);

  const [previousCalcState, setPreviousCalcState] = useState(userData.calcData || {});

  const [calcDataChanged, setCalcDataChanged] = useState(false);

  // Filter options
  const growthFilterOptions = [
    'Market growth',
    'Revenue growth',
    'EBITDA growth',
    'Gross Profit growth',
    'EBITDA Margin',
    'Gross Margin',
  ];
  const multiplesFilterOptions = ['Revenue multiples', 'EBITDA multiples'];
  const yearsFilterOptions = [...Array(40)].map((val, index) =>
    index / 4 + 0.25 <= 1 ? `${index / 4 + 0.25} year` : `${index / 4 + 0.25} years`,
  );
  const fiscalPeriodFilterOptions = [
    'Last fiscal year -1 (LFY-1)',
    'Last fiscal year (LFY)',
    'Last 12 months (LTM)',
    'Current fiscal year (CFY)',
    'Next 12 months (NTM)',
    'Next fiscal year (NFY)',
    'Next fiscal year +1 (NFY+1)',
    'Next fiscal year +2 (NFY+2)',
  ];

  const [filters, setFilters] = useState({
    showCompanyInfo: false,
    showRevenue: false,
    showEBITDA: false,
    showGrossProfit: false,
    showMarketCap: false,
    showVolatilityValDate: false,
    showVolatilityTrDate: false,
    growthFilterValues: [],
    multiplesFilterValues: [],
    yearsFilterValues: yearsFilterOptions,
    fiscalPeriodFilterValues: [],
  });

  const [industryError, setIndustryError] = useState(false);

  const [showVolatilityFootnote, setShowVolatilityFootnote] = useState(false);
  const [showBacksolveFootnote, setShowBacksolveFootnote] = useState(false);

  const [showComps, setShowComps] = useState(false);

  const [compRunProgress, setCompRunProgress] = useState({});

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const headerScroll = useRef(null);
  const subCompScroll = useRef(null);
  const rightCompXScroll = useRef(null);
  const rightCompYScroll = useRef(null);
  const overlayScroll = useRef(null);
  const summariesScroll = useRef(null);

  const checkCompStatusRef = useRef(null);

  const descriptionsRef = useRef({});
  const checkboxesRef = useRef({});

  function handleRightCompXScroll() {
    headerScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    if (overlayScroll.current)
      overlayScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    summariesScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
  }

  function handleOverlayScroll() {
    headerScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    rightCompXScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    summariesScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
  }

  function handleSummariesScroll() {
    headerScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    rightCompXScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    if (overlayScroll.current)
      overlayScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
  }

  function handleRightCompYScroll() {
    if (
      Math.abs(
        rightCompYScroll.current.scrollHeight -
          rightCompYScroll.current.scrollTop -
          rightCompYScroll.current.clientHeight,
      ) < 16 &&
      !navigator.userAgent.includes('Firefox')
    ) {
      rightCompYScroll.current.scrollTop =
        rightCompYScroll.current.scrollHeight - rightCompYScroll.current.clientHeight - 16;
    }
  }

  const rightCompXScrollRef = useCallback((node) => {
    if (rightCompXScroll?.current)
      rightCompXScroll.current.removeEventListener('scroll', handleRightCompXScroll);
    if (node) node.addEventListener('scroll', handleRightCompXScroll, { passive: true });
    rightCompXScroll.current = node;
  }, []);

  const rightCompYScrollRef = useCallback((node) => {
    if (rightCompYScroll?.current)
      rightCompYScroll.current.removeEventListener('scroll', handleRightCompYScroll);
    if (node) node.addEventListener('scroll', handleRightCompYScroll, { passive: true });
    rightCompYScroll.current = node;
  }, []);

  const overlayScrollbarRef = useCallback((node) => {
    if (overlayScroll?.current)
      overlayScroll.current.removeEventListener('scroll', handleOverlayScroll);
    if (node) node.addEventListener('scroll', handleOverlayScroll, { passive: true });
    overlayScroll.current = node;
  }, []);

  const summariesScrollRef = useCallback((node) => {
    if (summariesScroll?.current)
      summariesScroll.current.removeEventListener('scroll', handleSummariesScroll);
    if (node) node.addEventListener('scroll', handleSummariesScroll, { passive: true });
    summariesScroll.current = node;
  }, []);

  const [, transactionDataRequest] = useFetch();
  const [{ response: gotCompData }, compDataRequest] = useFetch();
  const [{ response: gotSummaryData }, summaryDataRequest] = useFetch();
  const [, updateTransactionRequest] = useFetch();
  const [, runCompRequest] = useFetch();
  const [{ loading: identifyingOutliers }, identifyOutliersRequest] = useFetch();
  const [{ loading: reSummarizing }, summarizeRequest] = useFetch();
  const [{ loading: removingIndustry }, removeIndustryRequest] = useFetch();
  const [, saveFootnotesRequest] = useFetch();
  const [, updateCalcEngineObjectRequest] = useFetch();
  const [, compEstimateRequest] = useFetch();

  const s3Bucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;

  const [{ response: compS3Location }, getS3CompRequest] = useS3();
  const [{ response: summaryS3Location }, getS3SummaryRequest] = useS3();

  function oldToNewFormat(oldFormat) {
    if (Array.isArray(oldFormat)) return oldFormat;
    const newFormat = [];
    const keys = Object.keys(oldFormat);
    keys.forEach((key) => {
      const values = oldFormat[key];
      values.forEach((val, index) => {
        if (!newFormat[index]) newFormat[index] = {};
        newFormat[index][key] = val;
      });
    });
    return newFormat;
  }

  function removeDuplicateComps(comps) {
    const uniqueIds = [];
    return comps.filter((comp) => {
      const isDuplicate = uniqueIds.includes(comp.Identifier);
      if (!isDuplicate) {
        uniqueIds.push(comp.Identifier);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    if (compS3Location && summaryS3Location) {
      compDataRequest({
        url: compS3Location,
        s3Call: true,
        onSuccess: (responseData) => {
          const resCompData = removeDuplicateComps(oldToNewFormat(responseData));
          setCompData(resCompData);
          setCurrentSummarySelections(
            resCompData
              .map((comp) => ({
                Identifier: comp.Identifier,
                Active: comp.Active,
                Multiples: comp.Multiples,
                Outlier: comp.Outlier,
              }))
              .sort((a, b) => a.Identifier.localeCompare(b.Identifier)),
          );
          descriptionsRef.current = resCompData.map(
            (_, i) => descriptionsRef.current[i] ?? createRef(),
          );
          checkboxesRef.current = resCompData.map(
            (_, i) => checkboxesRef.current[i] ?? createRef(),
          );
          setDescriptionsToShow([...Array(resCompData.length)].map(() => ''));
        },
      });
      summaryDataRequest({
        url: summaryS3Location,
        s3Call: true,
        onSuccess: (summaryResponse) => setSummariesData(oldToNewFormat(summaryResponse)),
      });
    }
  }, [compS3Location, summaryS3Location]);

  useEffect(() => {
    if (gotCompData && gotSummaryData) {
      setShowComps(true);
      setNoCompsToShow(false);
    }
  }, [gotCompData, gotSummaryData]);

  useEffect(() => {
    if (
      currentSummarySelections.length > 0 &&
      !isEqual(
        currentSummarySelections,
        compData
          .map((comp) => ({
            Identifier: comp.Identifier,
            Active: comp.Active,
            Multiples: comp.Multiples,
            Outlier: comp.Outlier,
          }))
          .sort((a, b) => a.Identifier.localeCompare(b.Identifier)),
      )
    ) {
      setSummariesOutdated(true);
    } else {
      setSummariesOutdated(false);
    }
  }, [compData]);

  function checkCompStatus() {
    if (checkCompStatusRef.current) {
      transactionDataRequest({
        url: '/transactions/asc820/get-enterprise-transaction-data/',
        urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'userId'],
        onSuccess: (responseData) => {
          if (
            responseData.transactionData.compStatus === 'complete' &&
            responseData.transactionData.compsLocation &&
            responseData.transactionData.compSummaryLocation
          ) {
            setTransactionData(responseData.transactionData);
            getS3CompRequest({
              key: responseData.transactionData.compsLocation.replace(
                `s3://${s3Bucket}/820Comps`,
                '',
              ),
              customPrefix: '820Comps',
              requestType: 'get',
            });
            getS3SummaryRequest({
              key: responseData.transactionData.compSummaryLocation.replace(
                `s3://${s3Bucket}/820Comps`,
                '',
              ),
              customPrefix: '820Comps',
              requestType: 'get',
            });
            setIndustries(
              userData.transactionData.basicInfo.companyIndustry &&
                userData.transactionData.basicInfo.companyIndustry.length
                ? userData.transactionData.basicInfo.companyIndustry
                : [''],
            );
            setSearchIndustryInput(
              userData.transactionData.basicInfo.companyIndustry &&
                userData.transactionData.basicInfo.companyIndustry.length
                ? userData.transactionData.basicInfo.companyIndustry
                : [''],
            );
            setCurrentIndustries(
              userData.transactionData.basicInfo.companyIndustry &&
                userData.transactionData.basicInfo.companyIndustry.length
                ? userData.transactionData.basicInfo.companyIndustry
                : [''],
            );
            clearInterval(checkCompStatusRef.current);
            checkCompStatusRef.current = null;
            setCompsToAdd([{ rowId: 0, compInput: '' }]);
            setRunningComps(false);
            setCompSuccess(true);
            setCompRunProgress({});
            setShowAddCompModal(false);
            setShowIndustriesModal(false);
            setUserData({ ...userData, transactionData: responseData.transactionData });
          } else if (responseData.transactionData.compStatus === 'error') {
            setShowErrorMessage(responseData.transactionData.compError);
            setRunningComps(false);
            setShowAddCompModal(false);
            setCompRunProgress({});
            setShowIndustriesModal(false);
            setCompsToAdd([{ rowId: 0, compInput: '' }]);
            setUserData({ ...userData, transactionData: responseData.transactionData });
            clearInterval(checkCompStatusRef.current);
            checkCompStatusRef.current = null;
          } else if (
            responseData.transactionData.compStatus === 'running' &&
            responseData.transactionData.compStartTimeInMs
          ) {
            if (
              Date.now() - parseInt(responseData.transactionData.compStartTimeInMs, 10) >
              300000
            ) {
              setRunningComps(false);
              const newTransactionData = {
                ...transactionData,
                compStatus: 'error',
              };
              delete newTransactionData.lastModified;
              setShowErrorMessage('Comps took too long to run. Please try again.');
              updateTransactionRequest({
                url: '/transactions/asc820/enterprise-update-transaction',
                method: 'post',
                body: newTransactionData,
                bodyIds: ['requestUserId'],
              });
            }
          }
        },
      });
    }
  }

  function parseTransactionInfo() {
    if (userData.transactionData.basicInfo) {
      setTransactionData(userData.transactionData);
      setIndustries(
        userData.transactionData.basicInfo.companyIndustry &&
        userData.transactionData.basicInfo.companyIndustry.length
          ? userData.transactionData.basicInfo.companyIndustry
          : [''],
      );
      setSearchIndustryInput(
        userData.transactionData.basicInfo.companyIndustry &&
        userData.transactionData.basicInfo.companyIndustry.length
          ? userData.transactionData.basicInfo.companyIndustry
          : [''],
      );
      setCurrentIndustries(
      userData.transactionData.basicInfo.companyIndustry &&
        userData.transactionData.basicInfo.companyIndustry.length
        ? userData.transactionData.basicInfo.companyIndustry
        : [''],
      );}
    if (userData.transactionData?.compsLocation && userData.transactionData?.compSummaryLocation) {
      getS3CompRequest({
        key: userData.transactionData.compsLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
        customPrefix: '820Comps',
        requestType: 'get',
      });
      getS3SummaryRequest({
        key: userData.transactionData.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
        customPrefix: '820Comps',
        requestType: 'get',
      });
    } else setNoCompsToShow(true);
    if (userData.transactionData.compStatus === 'running') {
      setRunningComps(true);
      if (!checkCompStatusRef.current) {
        checkCompStatusRef.current = setInterval(() => {
          checkCompStatus();
        }, 5000);
      }
    }
  }

  useEffect(() => {
    if (userData.transactionData) parseTransactionInfo();
  }, [userData.transactionData]);

  function runComps({ refresh = false } = {}) {
    setRunningComps(true);
    const newTransactionData = {
      ...transactionData,
      basicInfo: {
        ...transactionData.basicInfo,
        companyIndustry: industries,
      },
      version: parseInt(transactionData.version, 10),
    };
    delete newTransactionData.lastModified;
    updateTransactionRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: newTransactionData,
      bodyIds: ['requestUserId'],
      onError: () => setRunningComps(false),
      onSuccess: (responseData) => {
        const newCompData = {
          companyId: userData.metaData.portfolioCompanyId,
          transactionDate: transactionData.basicInfo.transactionDate
            ? transactionData.basicInfo.transactionDate
            : userData.transactionData.basicInfo.transactionDate,
          valuationDate: transactionData.basicInfo.valuationDate
            ? transactionData.basicInfo.valuationDate
            : userData.transactionData.basicInfo.transactionDate,
          financialStatementDate: transactionData.basicInfo.financialStatementDate
            ? moment(transactionData.basicInfo.financialStatementDate, 'YYYY-MM-DD').format(
              'MM/DD/YYYY',
            )
            : moment(
              userData.transactionData.basicInfo.financialStatementDate,
              'YYYY-MM-DD',
            ).format('MM/DD/YYYY'),
          ...(compsToAdd[0].compInput && {
            companies: compsToAdd
              .filter((comp) => comp.compInput !== '')
              .map((compToAdd) => compToAdd.compInput.trim()),
          }),
          verticals: industries,
          runType: refresh ? 'refresh' : compData.length > 0 ? 'add' : 'new',
        };
        compEstimateRequest({
          url: '/comps/get-comps-estimate',
          method: 'post',
          body: { ...newCompData, compData },
          bodyIds: ['transactionId', 'requestUserId'],
          onSuccess: (res) => {
            setCompRunProgress(res);
            setUserData({
              ...userData,
              transactionData: { ...responseData, compStatus: 'running' },
            });
            runCompRequest({
              url: '/comps/run-comps',
              method: 'post',
              body: newCompData,
              bodyIds: ['transactionId', 'requestUserId'],
              customError: true,
              onError: (e) => {
                if (e.message) setShowErrorMessage(e.message);
                setRunningComps(false);
                setUserData({
                  ...userData,
                  transactionData: { ...responseData, compStatus: 'error' },
                });
              },
            });
          },
          customError: true,
          onError: (e) => {
            if (e.message) setShowErrorMessage(e.message);
            setRunningComps(false);
            setUserData({ ...userData, transactionData: { ...responseData, compStatus: 'error' } });
          },
        });
      },
    });
  }

  function identifyOutliers() {
    const userId = getUserId();
    const compDataObject = {
      companyId: transactionData.portfolioCompanyId,
      transactionId: transactionData.transactionId,
      enterpriseCompanyId: transactionData.enterpriseCompanyId,
      compData: compData.map((comp) => ({ ...comp, Outlier: 0 })),
      requestUserId: userId,
    };
    identifyOutliersRequest({
      url: '/comps/identify-outliers',
      method: 'post',
      body: compDataObject,
      onSuccess: (responseData) => {
        setSummariesData(responseData.summary);
        const resCompData = removeDuplicateComps(oldToNewFormat(responseData.comps));
        setCompData(resCompData);
        setCurrentSummarySelections(
          resCompData
            .map((comp) => ({
              Identifier: comp.Identifier,
              Active: comp.Active,
              Multiples: comp.Multiples,
              Outlier: comp.Outlier,
            }))
            .sort((a, b) => a.Identifier.localeCompare(b.Identifier)),
        );
        setIdentifyingOutliersSuccess(true);
        setTimeout(() => {
          setIdentifyingOutliersSuccess(false);
        }, 3000);
      },
    });
  }

  function reSummarize(indexChanged, typeChanged) {
    let newCompData = copy(compData);
    if (indexChanged === 'all') {
      if (typeChanged === 'active') {
        newCompData = newCompData.some((comp) => comp.Active)
          ? newCompData.map((comp) => ({ ...comp, Active: 0 }))
          : newCompData.map((comp) => ({ ...comp, Active: 1 }));
      } else {
        newCompData = newCompData.some((comp) => comp.Multiples)
          ? newCompData.map((comp) => ({ ...comp, Multiples: 0 }))
          : newCompData.map((comp) => ({ ...comp, Multiples: 1 }));
      }
    } else if (typeChanged === 'add-both') {
      newCompData[indexChanged].Active = 1;
      newCompData[indexChanged].Multiples = 1;
    } else if (typeChanged === 'active') {
      newCompData[indexChanged].Active = newCompData[indexChanged].Active ? 0 : 1;
    } else if (typeChanged === 'multiple') {
      newCompData[indexChanged].Multiples = newCompData[indexChanged].Multiples ? 0 : 1;
    } else {
      newCompData[indexChanged].Outlier = newCompData[indexChanged].Outlier ? 0 : 1;
    }
    setCompData(removeDuplicateComps(oldToNewFormat(newCompData)));
  }

  function reSummarizeRequest() {
    summarizeRequest({
      url: '/comps/summarize-and-save-comps',
      method: 'post',
      body: { compData },
      bodyIds: ['portfolioCompanyId', 'transactionId', 'enterpriseCompanyId', 'requestUserId'],
      onSuccess: (res) => {
        setSummariesData(res.summary);
        const resCompData = removeDuplicateComps(oldToNewFormat(res.comps));
        setCompData(resCompData);
        setCurrentSummarySelections(
          resCompData
            .map((comp) => ({
              Identifier: comp.Identifier,
              Active: comp.Active,
              Multiples: comp.Multiples,
              Outlier: comp.Outlier,
            }))
            .sort((a, b) => a.Identifier.localeCompare(b.Identifier)),
        );
        setReSummarizeComplete(true);
        setTimeout(() => {
          setReSummarizeComplete(false);
          setSummariesOutdated(false);
        }, 3000);
        setUserData({
          ...userData,
          calcData: {
            ...userData.calcData,
            methods: {
              ...userData.calcData.methods,
              backsolve: {
                ...userData.calcData.methods.backsolve,
                suggestedMarketAdjustment: res.suggestedMarketAdjustment,
              },
            },
          },
        });
      },
    });
  }

  function removeIndustryFromComp(industryToRemove) {
    const compDataObject = {
      compData,
      industry: industryToRemove,
    };
    removeIndustryRequest({
      url: '/comps/remove-industry',
      method: 'post',
      body: compDataObject,
      bodyIds: ['portfolioCompanyId', 'transactionId', 'enterpriseCompanyId', 'requestUserId'],
      onSuccess: (responseData) => {
        const summariesRes = responseData?.summary;
        setSummariesData(summariesRes);
        const compResponse = responseData?.comps;
        const resCompData = removeDuplicateComps(oldToNewFormat(compResponse));
        setCompData(resCompData);
        setCurrentSummarySelections(
          resCompData
            .map((comp) => ({
              Identifier: comp.Identifier,
              Active: comp.Active,
              Multiples: comp.Multiples,
              Outlier: comp.Outlier,
            }))
            .sort((a, b) => a.Identifier.localeCompare(b.Identifier)),
        );
        descriptionsRef.current = compResponse.map(
          (_, i) => descriptionsRef.current[i] ?? createRef(),
        );
        checkboxesRef.current = compResponse.map((_, i) => checkboxesRef.current[i] ?? createRef());
        setDescriptionsToShow([...Array(compResponse.length)].map(() => ''));
        setCurrentIndustries(currentIndustries.filter((el) => el !== industryToRemove));
        updateTransactionRequest({
          url: '/transactions/asc820/enterprise-update-transaction',
          method: 'post',
          body: {
            ...transactionData,
            basicInfo: {
              ...transactionData.basicInfo,
              companyIndustry: currentIndustries.filter((el) => el !== industryToRemove),
            },
          },
          bodyIds: ['requestUserId'],
        });
      },
    });
  }

  function saveFootnotes(data, dataName) {
    const saveFootnoteData = {
      methodsFootnotes: [],
      payload: data,
      dataName,
      ...(userData?.gridData?.defaultScenarios.term &&
      userData?.gridData?.defaultScenarios.volatility
        ? {
            term: new Intl.NumberFormat('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 1,
            }).format(userData.gridData?.defaultScenarios.term),
            volatility: userData.gridData.defaultScenarios.volatility,
          }
        : {
            term: new Intl.NumberFormat('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 1,
            }).format(userData.gridData?.defaultScenarios?.term),
            volatility: userData.gridData?.defaultScenarios?.volatility.toFixed(1).toString(),
          }),
    };
    saveFootnotesRequest({
      url: '/footnotes/save-footnotes',
      method: 'post',
      body: saveFootnoteData,
      bodyIds: ['portfolioCompanyId', 'enterpriseCompanyId', 'transactionId', 'requestUserId'],
    });
  }

  useEffect(() => {
    if (calcDataChanged && !isEqual(userData.calcData, previousCalcState)) {
      updateCalcEngineObjectRequest({
        url: '/calc-engine/update-calc-engine-object',
        method: 'post',
        body: userData.calcData,
        onSuccess: () => setPreviousCalcState(userData.calcData),
        onFinally: () => setCalcDataChanged(false),
      });
    }
  }, [calcDataChanged, userData.calcData, previousCalcState]);

  function getSuggestedMarketAdjustment() {
    if (summariesData.length > 0) {
      const suggestedMarketAdjustment = (summariesData[5]['Market Cap Growth'] * 100).toFixed(2);
      setUserData({
        ...userData,
        calcData: {
          ...userData.calcData,
          methods: {
            ...userData.calcData.methods,
            backsolve: {
              ...userData.calcData.methods.backsolve,
              suggestedMarketAdjustment,
            },
          },
        },
      });
    }
  }

  useEffect(() => {
    getSuggestedMarketAdjustment();
  }, [summariesData]);

  return (
    <div className='Comps'>
      {!!transactionData.basicInfo?.valuationDate &&
      !!transactionData.basicInfo?.transactionDate &&
      !!transactionData.basicInfo.financialStatementDate ? (
        <>
          {tabSelected === 'comps' && (
            <div className='header-info-block'>
              <h4>
                <SelectAllIcon />
                Select industries, comps & equity
              </h4>
              <p>
                Add companies, select comparable companies, and weight scenarios at the bottom of
                this page before you head over
                <br />
                to the Calculations tab to calculate Backsolve, PubCo and M&A methods.
                {showComps && (
                  <>
                    <span> Need to update the data in the company list?</span>
                    <Button
                      onClick={() => {
                        setShowRefreshCompsModal(true);
                      }}
                    >
                      Click here
                    </Button>
                    .
                  </>
                )}
              </p>
            </div>
          )}
          {tabSelected === 'industry' && (
            <div className='header-info-block'>
              <h4>
                <QueryStatsIcon />
                Choose volatility
              </h4>
            </div>
          )}
          {tabSelected === 'industry' && (
            <div className='volatility-inputs-wrapper'>
              <div className='volatility-inputs-header'>
                <span>Choose a volatility percentile</span>
                <p>
                  The volatility percentile can be selected and edited from this tab and from the
                  Calculations tab. Our system updates both tabs simultaneously. Don&apos;t worry
                  about being precise, you&apos;ll be able to run a range of volatilities and make a
                  final selection from the Calculations tab, plus you can edit this and rerun models
                  on the Calculations tab at any time.
                </p>
              </div>

              <div className='volatility-inputs-content'>
                <div
                  className={`volatility-input-wrapper ${
                    showVolatilityFootnote ? ' show-radius' : ''
                  }`}
                >
                  <div className='volatility-input-top'>
                    <TextField
                      label='Volatility percentile'
                      value={userData.calcData?.limits.volatility.quantile || ''}
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.limits.volatility.quantile = e.target.value;
                        setUserData(newData);
                        setCalcDataChanged(true);
                      }}
                      select
                      SelectProps={{
                        MenuProps: {
                          disableScrollLock: true,
                          classes: { paper: 'select-dropdown' },
                        },
                      }}
                    >
                      {[
                        { value: 'min', label: 'Minimum' },
                        { value: '5%', label: '5th percentile' },
                        { value: '10%', label: '10th percentile' },
                        { value: '25%', label: '25th percentile' },
                        { value: '50%', label: 'Median' },
                        { value: '75%', label: '75th percentile' },
                        { value: '90%', label: '90th percentile' },
                        { value: '95%', label: '95th percentile' },
                        { value: 'max', label: 'Maximum' },
                        { value: 'mean', label: 'Average' },
                      ].map((menuItem) => (
                        <MenuItem key={menuItem.label} value={menuItem.value}>
                          {menuItem.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button onClick={() => setShowVolatilityFootnote(!showVolatilityFootnote)}>
                      {`${showVolatilityFootnote ? 'Hide' : 'Show'} footnote`}
                    </Button>

                  </div>
                  <AnimateHeight duration={300} height={showVolatilityFootnote ? 'auto' : 0}>
                    <div className='volatility-input-footnote'>
                      <span>Volatility footnote&nbsp;</span>
                      <span>will appear in client&apos;s report. </span>
                      <TextField
                        value={userData?.footnoteData?.volPercentFn || ''}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            footnoteData: { ...prev.footnoteData, volPercentFn: e.target.value },
                          }))
                        }
                        onBlur={() =>
                          saveFootnotes(
                            { volPercentFn: userData?.footnoteData?.volPercentFn || '' },
                            'volPercentFn',
                          )
                        }
                        multiline
                        maxRows={5}
                        inputProps={{ maxLength: 1000 }}
                      />
                      <div className='footnote-characters-left'>
                        {`${
                          userData?.footnoteData?.volPercentFn
                            ? userData?.footnoteData?.volPercentFn?.length
                            : 0
                        }/1000`}
                      </div>
                    </div>
                  </AnimateHeight>
                </div>
                <div
                  className={`volatility-input-wrapper ${
                    showBacksolveFootnote ? ' show-radius' : ''
                  }`}
                >
                  <div className='volatility-input-top'>
                    <TextField
                      label='Volatility for Backsolve (optional)'
                      value={
                        userData.calcData?.methods.backsolve.manualTransactionDateVolatility || ''
                      }
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.methods.backsolve.manualTransactionDateVolatility =
                          e.target.value;
                        setUserData(newData);
                      }}
                      onBlur={(e) => {
                        const newData = copy(userData);
                        newData.calcData.methods.backsolve.manualTransactionDateVolatility = e
                          .target.value
                          ? `${e.target.value.replaceAll('%', '')}%`
                          : null;
                        setUserData(newData);
                        setCalcDataChanged(true);
                      }}
                    />
                    <TextField
                      label='Term for Backsolve (optional)'
                      value={userData.calcData?.methods.backsolve.manualTransactionDateTerm || ''}
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.methods.backsolve.manualTransactionDateTerm =
                          e.target.value;
                        setUserData(newData);
                      }}
                      onBlur={(e) => {
                        const newData = copy(userData);
                        let formattedNum = new Intl.NumberFormat('en-US').format(
                          e.target.value.replace(/[^\d.]/g, '').trim(),
                        );
                        if (parseFloat(formattedNum) > 10) formattedNum = '10';
                        newData.calcData.methods.backsolve.manualTransactionDateTerm =
                          formattedNum !== '0' || !formattedNum
                            ? `${formattedNum} year${parseFloat(formattedNum) > 1 ? 's' : ''}`
                            : null;
                        setUserData(newData);
                        setCalcDataChanged(true);
                      }}
                    />
                    <Button onClick={() => setShowBacksolveFootnote(!showBacksolveFootnote)}>
                      {`${showBacksolveFootnote ? 'Hide' : 'Show'} footnote`}
                    </Button>
                  </div>
                  <AnimateHeight duration={300} height={showBacksolveFootnote ? 'auto' : 0}>
                    <div className='volatility-input-footnote'>
                      <span>Transaction date volatility for Backsolve footnote&nbsp;</span>
                      <span>will appear in client&apos;s report. </span>
                      <TextField
                        value={userData?.footnoteData?.tdVolatilityBacksolve || ''}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            footnoteData: {
                              ...prev.footnoteData,
                              tdVolatilityBacksolve: e.target.value,
                            },
                          }))
                        }
                        onBlur={() =>
                          saveFootnotes({
                            tdVolatilityBacksolve:
                              userData?.footnoteData?.tdVolatilityBacksolve || '',
                          })
                        }
                        multiline
                        maxRows={5}
                        inputProps={{ maxLength: 1000 }}
                      />
                      <div className='footnote-characters-left'>
                        {`${
                          userData?.footnoteData?.tdVolatilityBacksolve
                            ? userData?.footnoteData?.tdVolatilityBacksolve?.length
                            : 0
                        }/1000`}
                      </div>
                    </div>
                  </AnimateHeight>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='header-info-block'>
          <h4>
            <SelectAllIcon />
            Industries, comps & equity
          </h4>
          <p>
            <span>Unlock this tab&nbsp;</span>- click &apos;check for missing information&apos;,
            then:
          </p>
          <ul>
            <li>
              if all necessary information has been filled in on the &apos;820 inputs&apos; tab,
              like the transaction date and valuation date, this tab will unlock.
            </li>
            <li>
              if you haven&apos;t submitted all necessary information, you&apos;ll be redirected to
              where all missing information is located. All required information will be highlighted
              for your convenience.
            </li>
          </ul>
          <Button
            className='reroute-btn'
            onClick={() => {
              setCheckForMissingCompInputs(true);
              setTabSelected('820-inputs');
            }}
          >
            Check for missing information now
          </Button>
        </div>
      )}
      {!!transactionData.basicInfo?.valuationDate &&
      !!transactionData.basicInfo?.transactionDate &&
      !!transactionData.basicInfo.financialStatementDate ? (
        <div className='industries-select-wrapper'>
          <div className='add-companies-view'>
            <div className='add-companies-description'>
              <h6>Add companies</h6>
            </div>
            <div className='submit-btns-container'>
              <p>
                Click &apos;Add industries&apos; to add pre-curated industry list of companies. To
                add individual companies or to add companies in bulk, click &apos;Add
                companies&apos;.
              </p>
              <Button
                className='industries-btn'
                onClick={() => {
                  setIndustryError(false);
                  setShowIndustriesModal(true);
                }}
              >
                <AddBusinessOutlinedIcon />
                Add industries
              </Button>
              <Button
                className='submit-btn'
                onClick={() => {
                  setIndustryError(false);
                  setShowAddCompModal(true);
                }}
              >
                <AddIcon />
                Add companies
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <p className='no-comps-to-show'>Company and industry info will show here.</p>
      )}
      {!showComps && !noCompsToShow && (
        <div className='loading-comps'>
          <div className='dots-circle-spinner' />
        </div>
      )}
      {showComps && (
        <>
          <div className='comps-wrapper'>
            <div className='comps-header'>
              <div className='comps-header-text'>
                <div>
                  {tabSelected === 'industry' && <h6>Select volatility guideline companies</h6>}
                  {tabSelected === 'comps' && <h6>Select comparable companies</h6>}
                  <p>
                    Click the checkbox next to a company&apos;s name to select and deselect
                    individual companies. To select or deselect all, click the checkbox in the
                    Company list header.
                  </p>
                </div>
                {identifyingOutliersSuccess ? (
                  <div className='outlier-status'>
                    <CheckCircleOutlineIcon />
                    Outliers flagged
                  </div>
                ) : identifyingOutliers ? (
                  <div className='outlier-status'>
                    <span className='dots-circle-spinner' />
                    Checking for outliers
                  </div>
                ) : (
                  <Tooltip
                    title={
                      <>
                        This will override any currently selected outliers.
                        <br />
                        It will automatically flag outliers using the volatility&apos;s
                        <br />
                        Inter-Quartile (IQR) method.
                        <br />
                        To read more about IQR,&nbsp;
                        <a
                          className='iqr-link'
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://en.wikipedia.org/wiki/Interquartile_range'
                        >
                          click here
                        </a>
                        .
                      </>
                    }
                    PopperProps={{ className: 'bottom-arrow-tooltip iqr-tooltip' }}
                    placement='top'
                    arrow
                  >
                    <Button onClick={() => identifyOutliers()}>
                      <FlagIcon />
                      Check for outliers
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <FilterMenus
              tabSelected={tabSelected}
              growthFilterOptions={growthFilterOptions}
              multiplesFilterOptions={multiplesFilterOptions}
              yearsFilterOptions={yearsFilterOptions}
              fiscalPeriodFilterOptions={fiscalPeriodFilterOptions}
              filters={filters}
              setFilters={setFilters}
              compsColumns={compsColumns}
              setCompsColumns={setCompsColumns}
              descriptionsToShow={descriptionsToShow}
              setDescriptionsToShow={setDescriptionsToShow}
            />
            <CompTable
              tabSelected={tabSelected}
              compData={compData}
              setCompData={setCompData}
              filters={filters}
              compsColumns={compsColumns}
              setCompsColumns={setCompsColumns}
              setShowAddCompModal={setShowAddCompModal}
              headerScroll={headerScroll}
              subCompScroll={subCompScroll}
              overlayScrollbarRef={overlayScrollbarRef}
              rightCompXScrollRef={rightCompXScrollRef}
              rightCompYScroll={rightCompYScroll}
              rightCompYScrollRef={rightCompYScrollRef}
              // eslint-disable-next-line
              reSummarize={reSummarize}
              summariesOutdated={summariesOutdated}
              runningComps={runningComps}
              descriptionsToShow={descriptionsToShow}
              setDescriptionsToShow={setDescriptionsToShow}
              descriptionsRef={descriptionsRef}
              checkboxesRef={checkboxesRef}
            />
            <SummariesTable
              tabSelected={tabSelected}
              compData={compData}
              summariesData={summariesData}
              compsColumns={compsColumns}
              setCompsColumns={setCompsColumns}
              reSummarizing={reSummarizing || removingIndustry}
              reSummarizeRequest={reSummarizeRequest}
              reSummarizeComplete={reSummarizeComplete}
              summariesOutdated={summariesOutdated}
              filters={filters}
              summariesScrollRef={summariesScrollRef}
            />
          </div>
          {tabSelected === 'comps' && (
            <EquityInputs
              userData={userData}
              setUserData={setUserData}
              setSelectedGridValue={setSelectedGridValue}
            />
          )}
        </>
      )}
      <AddCompModal
        showAddCompModal={showAddCompModal}
        setShowAddCompModal={setShowAddCompModal}
        showIndustriesModal={showIndustriesModal}
        setShowIndustriesModal={setShowIndustriesModal}
        runningComps={runningComps}
        removingIndustry={removingIndustry}
        compsToAdd={compsToAdd}
        setCompsToAdd={setCompsToAdd}
        runComps={runComps}
        setIndustryError={setIndustryError}
        industryError={industryError}
        userData={userData}
        industries={industries}
        setIndustries={setIndustries}
        searchIndustryInput={searchIndustryInput}
        setSearchIndustryInput={setSearchIndustryInput}
        currentIndustries={currentIndustries}
        removeIndustryFromComp={removeIndustryFromComp}
        compRunProgress={compRunProgress}
      />
      <RefreshCompsModal
        showRefreshCompsModal={showRefreshCompsModal}
        setShowRefreshCompsModal={setShowRefreshCompsModal}
        runComps={runComps}
      />
    </div>
  );
}
