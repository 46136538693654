import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import InfoTile from './components/InfoTile';

import { commaEvery3rdChar, copy } from '../../utils';

import useFetch from '../../hooks/useFetch';

import './ClientInputs.scss';
import { AppDataContext, UserContext } from '../../contexts';

export default function ClientInputs() {
  const { userData, setUserData } = useContext(UserContext);
  const {checkForMissingCompInputs, setCheckForMissingCompInputs,exitingToTab, setExitingToTab, setTabSelected,} =  useContext(AppDataContext)
  const [isEditing, setIsEditing] = useState(false);

  const [originalTransactionData, setOriginalTransactionData] = useState(false);

  const [savingEdits, setSavingEdits] = useState(false);

  const [showTooltip, setShowTooltip] = useState(true);

  const [basicInfo, setBasicInfo] = useState({});
  const [enterpriseFinancialInfo, setEnterpriseFinancialInfo] = useState({});
  const [clientFinancialInfo, setClientFinancialInfo] = useState({});
  const [fundingInfo, setFundingInfo] = useState({});
  const [capTableUploads, setCapTableUploads] = useState([]);
  const [articlesUploads, setArticlesUploads] = useState([]);
  const [scheduleOfInvestmentsUploads, setScheduleOfInvestmentsUploads] = useState([]);
  const [scheduleOfInvestmentsUploadsPrevState, setScheduleOfInvestmentsUploadsPrevState] = useState([]);
  const [financialForecastUploads, setFinancialForecastUploads] = useState([]);
  const [additionalFinancialUploads, setAdditionalFinancialUploads] = useState([]);
  const [secondaryTransactionsUploads, setSecondaryTransactionsUploads] = useState([]);

  const [prevTrInputId, setPrevTrInputId] = useState(0);
  const [previousTransactions, setPreviousTransactions] = useState([]);
  const [lastRoundInformation, setLastRoundInformation] = useState({ performanceMetrics: {} });

  const [errorStates, setErrorStates] = useState({ valuationDate: false, transactionDate: false, financialStatementDate: false });

  const [triggerSave, setTriggerSave] = useState(false);

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const changeInProgressRef = useRef(false);
  const changeHasBeenMadeRef = useRef(false);

  const [, updateTransactionRequest] = useFetch();
  const [, updateProjectRequest] = useFetch();

  function parseTransactionData() {
    setBasicInfo(userData.transactionData.basicInfo || {});
    setEnterpriseFinancialInfo(userData.transactionData.enterpriseFinancialInfo || {});
    setClientFinancialInfo(userData.transactionData.clientFinancialInfo || {});
    setFundingInfo(userData.transactionData.fundingInfo || {});
    setCapTableUploads(userData.transactionData.capTableUploads || []);
    setArticlesUploads(userData.transactionData.articlesUploads || []);
    setScheduleOfInvestmentsUploads(userData.projectDetails.scheduleOfInvestmentsUploads || []);
    setScheduleOfInvestmentsUploadsPrevState(userData.projectDetails.scheduleOfInvestmentsUploads || []);
    setFinancialForecastUploads(userData.transactionData?.financialForecastUploads || []);
    setAdditionalFinancialUploads(userData.transactionData?.additionalFinancialUploads || []);
    setSecondaryTransactionsUploads(userData.transactionData?.secondaryTransactionsUploads || []);
    setPreviousTransactions(userData.transactionData?.previousTransactions?.length ?
      userData.transactionData.previousTransactions.map((t, i) => {
        const prevTransactionCopy = { ...t };
        return ({ ...prevTransactionCopy, prevTrId: prevTrInputId + i });
      }) : [{ prevTrId: 0 }]);
    setLastRoundInformation(userData.transactionData.lastRoundInformation || {});
    setPrevTrInputId(userData.transactionData?.previousTransactions?.length || 1);
  }

  useEffect(() => { if (userData.transactionData) { parseTransactionData(); } }, [userData]);

  useEffect(() => {
    if (checkForMissingCompInputs) {
      setErrorStates({
        valuationDate: !userData.transactionData?.basicInfo?.valuationDate,
        transactionDate: !userData.transactionData?.basicInfo?.transactionDate,
        financialStatementDate: !userData.transactionData?.basicInfo?.financialStatementDate,
      });
      setIsEditing(true);
    }
    setCheckForMissingCompInputs(false);
  }, [checkForMissingCompInputs]);

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  function saveData({ autoSave = false, exitingTab = false, saveUploads = false } = {}) {
    if (autoSave && (!changeHasBeenMadeRef.current || changeInProgressRef.current)) return;
    if (!autoSave && !saveUploads) setSavingEdits(true);
    setErrorStates({ transactionDate: false, valuationDate: false, financialStatementDate: false });

    const transactionDBData = {
      ...userData.transactionData,
      basicInfo,
      enterpriseFinancialInfo,
      clientFinancialInfo,
      fundingInfo,
      capTableUploads,
      articlesUploads,
      financialForecastUploads,
      additionalFinancialUploads,
      secondaryTransactionsUploads,
      version: parseInt(userData.transactionData.version, 10),
      previousTransactions,
      lastRoundInformation,
    };
    updateTransactionRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: transactionDBData,
      bodyIds: ['projectId', 'requestUserId'],
      onSuccess: () => { if (!autoSave && !saveUploads) setUserData({ ...userData, transactionData: transactionDBData }); },
      onFinally: () => {
        if (isEqual(scheduleOfInvestmentsUploads, scheduleOfInvestmentsUploadsPrevState)) {
          setSavingEdits(false);
          setChangeHasBeenMade(false);
          if (!autoSave && !saveUploads) setIsEditing(false);
          if (exitingTab) setTabSelected(exitingToTab);
        }
      },
    });
    if (!isEqual(scheduleOfInvestmentsUploads, scheduleOfInvestmentsUploadsPrevState)) {
      updateProjectRequest({
        url: '/projects/asc820/update-project-details', method: 'post',
        body: { ...userData.projectDetails, scheduleOfInvestmentsUploads },
        bodyIds: ['requestUserId'],
        onSuccess: () => {
          if (!autoSave && !saveUploads) {
            setUserData({ ...userData, projectDetails: { ...userData.projectDetails, scheduleOfInvestmentsUploads } });
          }
          setSavingEdits(false);
        },
        onFinally: () => {
          setSavingEdits(false);
          setChangeHasBeenMade(false);
          if (!autoSave && !saveUploads) setIsEditing(false);
          if (exitingTab) setTabSelected(exitingToTab);
        },
      });
    }
  }

  useEffect(() => {
    const prevTranCopy = copy(previousTransactions);
    const prevWeightedEquityValue = parseFloat(prevTranCopy[0]?.equityValue?.replaceAll(',', ''));
    const prevTotalDebt = parseFloat(prevTranCopy[0]?.totalDebt?.replaceAll(',', ''));
    const prevTotalCash = parseFloat(prevTranCopy[0]?.totalCash?.replaceAll(',', ''));
    if (prevTotalCash && prevTotalDebt && prevWeightedEquityValue) {
      const prevEnterpriseValue = prevWeightedEquityValue + prevTotalDebt - prevTotalCash;
      setPreviousTransactions([{ ...prevTranCopy[0], enterpriseValue: commaEvery3rdChar(prevEnterpriseValue) }]);
    }
  }, [
    previousTransactions[0]?.equityValue,
    previousTransactions[0]?.totalDebt,
    previousTransactions[0]?.totalCash,
  ]);

  useEffect(() => {
    if (exitingToTab && isEditing) saveData({ exitingTab: true });
    else if (exitingToTab) setTabSelected(exitingToTab);
    setExitingToTab(false);
  }, [exitingToTab]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => { savedCallback.current = callback; }, [callback]);

    useEffect(() => {
      const func = () => { savedCallback.current(); };
      if (delay !== null) {
        const id = setInterval(func, delay);
        return () => clearInterval(id);
      }
      return null;
    }, [delay]);
  }

  useInterval(() => saveData({ autoSave: true }), 5000);

  useEffect(() => {
    if (triggerSave) {
      saveData({ saveUploads: true });
      setTriggerSave(false);
    }
  }, [triggerSave]);

  return (
    <div className="ClientInputs">
      {showTooltip && (
        <div className="tooltip-info-block">
          <LightbulbOutlinedIcon />
          <div>
            <p>
              The information below will fill in as the client fills in their ASC 820 submission form.
              That&apos;s the secure form we sent them when you started the ASC 820.
            </p>
            <p>Click edit to fill in information for the client or to correct incorrect information.</p>
          </div>
          <IconButton
            className="close-icon"
            onClick={() => setShowTooltip(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {Object.values(errorStates).some((errorState) => errorState) && (
        <div className="error-info-block">
          <ErrorIcon />
          <p>
            Missing required fields&nbsp;
            {
              `${errorStates.transactionDate && errorStates.valuationDate && errorStates.financialStatementDate ?
                "'Valuation date', 'Transaction date' and 'Last financial statement date'" :
                errorStates.valuationDate && errorStates.financialStatementDate ?
                  "'Valuation date' and 'Last financial statement date'" :
                  errorStates.transactionDate && errorStates.financialStatementDate ?
                    "'Transaction date' and 'Last financial statement date'" :
                    errorStates.transactionDate && errorStates.valuationDate ?
                      "'Valuation date' and 'Transaction date'" :
                      errorStates.valuationDate ?
                        "'Valuation date'" :
                        errorStates.transactionDate ?
                          "'Transaction date'" :
                          errorStates.financialStatementDate ?
                            "'Last financial statement date'" :
                            ''
              }`
            }
            . Please provide missing information then &apos;Save edits&apos;.
          </p>
        </div>
      )}
      <div className="edit-btn-wrapper">
        {!isEditing ? (
          <Button
            className="edit-btn"
            onClick={() => {
              setIsEditing(true);
              setOriginalTransactionData(userData.transactionData);
            }}
          >
            <EditIcon />
            Edit
          </Button>
        ) : (
          <>
            <Button onClick={() => {
              setUserData({ ...userData, transactionData: originalTransactionData });
              setIsEditing(false);
              setChangeHasBeenMade(true);
              setErrorStates({ valuationDate: false, transactionDate: false, financialStatementDate: false });
            }}
            >
              <CancelOutlinedIcon />
              Cancel
            </Button>
            <Button onClick={() => saveData()}>
              {savingEdits ? (
                <>
                  <span className="dots-circle-spinner" />
                  Saving
                </>
              ) : (
                <>
                  <SaveOutlinedIcon />
                  Save edits
                </>
              )}
            </Button>
          </>
        )}
      </div>
      <div className="input-block">
        <div className="block-header">
          <h5>Dates, exit and Cap Table info</h5>
        </div>
        <div className="input-tiles block-two">
          <InfoTile
            label="Valuation date"
            dataName="valuationDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Transaction date"
            dataName="transactionDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Last financial statement date"
            dataName="financialStatementDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Company industry"
            dataName="companyIndustry"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Most likely type of exit event"
            dataName="typeOfExit"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Merger and acquisition (M&A)',
              'Initial public offering (IPO)',
              'Don\'t know',
              'Other',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Most likely timing of exit event"
            dataName="exitTiming"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Less than 1 year',
              '1 - 2 year(s)',
              '2 - 3 years',
              '3 - 4 years',
              '4 - 5 years',
              '5 years or more',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Capitalization table provider"
            dataName="capTableProvider"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Carta',
              'Pulley',
              'Shareworks',
              'Other',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Capitalization 'as of' date"
            dataName="capTableDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
        </div>
      </div>
      <div className="input-block block-three">
        <div className="block-header">
          <h5>Financials</h5>
        </div>
        <div className="input-tiles">
          <InfoTile
            label="Total invested capital"
            dataName="totalInvestedCapital"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Total current debt"
            dataName="totalDebt"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Total non-convertible debt"
            dataName="nonConvertibleDebt"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Debt as of transaction date (if applicable)"
            dataName="debtAsOfTransactionDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            add$
            addCommas
          />
          <InfoTile
            label="Total current cash"
            dataName="currentCash"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Cash runway (in months)"
            dataName="monthlyCashBurn"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            addMonths
          />
          <InfoTile
            label="Company has or will generate revenue over $1 million in the next year?"
            dataName="EBITDAChecked"
            isEditing={isEditing}
            values={enterpriseFinancialInfo}
            setValues={setEnterpriseFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isRadio
          />
        </div>
      </div>
      <div className="input-block">
        <div className="block-header">
          <h5>Funding and secondary transactions</h5>
        </div>
        <div className="input-tiles block-two">
          <InfoTile
            label="Company performance"
            dataName="companyPerformance"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            dropdownValues={[
              '5. Far exceed expectations',
              '4. Exceed expectations',
              '3. Perform as expected',
              '2. Actual results below projections provided to investors',
              '1. Actual results far below projections provided to investors',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Type of latest round of funding"
            dataName="latestRoundType"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            dropdownValues={[
              'SAFE Note',
              'Convertible Note',
              'Angel',
              'Pre-Seed',
              'Series Seed',
              'Series A',
              'Series B',
              'Series C',
              'Series D',
              'Series E',
              'Series F',
              'Series G',
              'Series H',
              'Series I',
              'Series J',
              'Series K',
              'Series L',
              'No funding',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Next round expected pre-money"
            dataName="nextRoundExpectedPreMoney"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Next round expected post-money"
            dataName="nextRoundExpectedPostMoney"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Have there been any secondary transactions in the company's equity in the last 12 months?"
            dataName="secondaryTransactions"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isRadio
          />
          <InfoTile
            label="Share class"
            dataName="secondaryTransactionShareClass"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Number of shares"
            dataName="secondaryTransactionNumberOfShares"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Price per share"
            dataName="secondaryTransactionPricePerShare"
            isEditing={isEditing}
            values={fundingInfo}
            setValues={setFundingInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
            add$
          />
        </div>
      </div>
      <div className="input-block block-four">
        <div className="block-header header-four">
          {[
            ['Last fiscal year - 1', 'LFY-1'],
            ['Last fiscal year', 'LFY'],
            ['Last twelve months', 'LTM'],
            ['Current fiscal year', 'CFY'],
            ['Next 12 months', 'NTM'],
            ['Next fiscal year', 'NFY'],
            ['Next fiscal year + 1', 'NFY +1'],
            ['Next fiscal year + 2', 'NFY +2'],
          ].map((label) => (
            <span key={label[0]}>
              {label[0]}
              <br />
              <p>
                {label[1]}
                &nbsp;($)
              </p>
            </span>
          ))
          }
        </div>
        <div className="input-tiles fiscal-periods">
          {['Revenue', 'GrossProfit', 'EBITDA'].map((rowName, idx) => (
            <div key={rowName} className={`indicator-rows${idx % 2 === 0 ? ' even' : ' odd'}`}>
              <span className={`${rowName.toLowerCase()}`}>{rowName === 'GrossProfit' ? 'Gross Profit' : rowName}</span>
              {[
                `lastFiscalYear${rowName}M1`,
                `lastFiscalYear${rowName}`,
                `last12Months${rowName}`,
                `currentFiscalYear${rowName}`,
                `next12Months${rowName}`,
                `nextFiscalYear${rowName}`,
                `nextFiscalYear${rowName}P1`,
                `nextFiscalYear${rowName}P2`,
              ].map((dataName) => (
                <InfoTile
                  key={dataName}
                  label=""
                  dataName={dataName}
                  isEditing={isEditing}
                  values={enterpriseFinancialInfo}
                  setValues={setEnterpriseFinancialInfo}
                  setChangeInProgress={setChangeInProgress}
                  setChangeHasBeenMade={setChangeHasBeenMade}
                  inputTracking
                  add$
                  addCommas
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <hr />
      {previousTransactions.length && previousTransactions.map((prevTr, index) => (
        <React.Fragment key={prevTr.prevTrId}>
          <div className="input-block block-five">
            <div className="info-header">
              <h4>Previous valuation information for &apos;Value comparison&apos; exhibit</h4>
              <p>
                The below fields are only necessary for clients that aren&apos;t already in our system.
                This information will be used for the &apos;Valuation comparison&apos; exhibit.
                <br />
                Only include the data you want shown in the report.
              </p>
            </div>
            <div className="input-tiles">
              <InfoTile
                label="Date of previous valuation"
                dataName="valuationDate"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                isDate
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="Concluded weighted equity value (previous val.)"
                dataName="equityValue"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="Total debt (previous val.)"
                dataName="totalDebt"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="Total cash (previous val.)"
                dataName="totalCash"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="Enterprise value (previous val.)"
                dataName="enterpriseValue"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="Holding value (previous val.)"
                dataName="holdingValue"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
              <InfoTile
                label="New money/additional investment (since previous val.)"
                dataName="newMoneyPrevValuation"
                isEditing={isEditing}
                values={previousTransactions[index]}
                setValues={setPreviousTransactions}
                setChangeInProgress={setChangeInProgress}
                setChangeHasBeenMade={setChangeHasBeenMade}
                add$
                addCommas
                index={index}
                isObjFromArr
              />
            </div>
          </div>
          <div className="block-header prev-val">
            <h5>&apos;As of&apos; previous valuation</h5>
          </div>
          <div className="input-block block-four">
            <div className="block-header header-four previous-val">
              {[
                ['Last fiscal year', 'previous val. LFY ($)'],
                ['Last twelve months', 'previous val. LTM ($)'],
                ['Current fiscal year', 'previous val. CFY ($)'],
                ['Next 12 months', 'previous val. NTM ($)'],
                ['Next fiscal year', 'previous val. NFY ($)'],
              ].map((label) => (
                <span key={label[0]}>
                  {label[0]}
                  <br />
                  <p>
                    {label[1]}
                  </p>
                </span>
              ))
              }
            </div>
            <div className="input-tiles fiscal-periods prev-val">
              {['Revenue', 'EBITDA'].map((rowName, idx) => (
                <div key={rowName} className={`indicator-rows${idx % 2 === 0 ? ' even' : ' odd'}`}>
                  <span className={`${rowName.toLowerCase()}`}>{rowName}</span>
                  {[
                    `lastFiscalYear${rowName}`,
                    `last12Months${rowName}`,
                    `currentFiscalYear${rowName}`,
                    `next12Months${rowName}`,
                    `nextFiscalYear${rowName}`,
                  ].map((dataName) => (
                    <InfoTile
                      key={dataName}
                      label=""
                      dataName={dataName}
                      isEditing={isEditing}
                      values={previousTransactions[index]}
                      setValues={setPreviousTransactions}
                      setChangeInProgress={setChangeInProgress}
                      setChangeHasBeenMade={setChangeHasBeenMade}
                      index={0}
                      add$
                      addCommas
                      isObjFromArr
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className="input-block block-five">
        <div className="info-header">
          <h4>Previous transaction date information</h4>
          <p>
            Information about the most recent transaction. Used for the &apos;Calibration&apos; section of the &apos;Value comparison&apos; exhibit.
            <br />
            Only include the data you want shown in the report.
          </p>
        </div>
        <div className="input-tiles prev-transaction">
          <InfoTile
            label="Date of previous transaction"
            dataName="transactionDate"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Previous transaction type"
            dataName="prevTransactionType"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Issue price (if transaction since previous valuation)"
            dataName="issuePrice"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            add$
            addCommas
          />
          <InfoTile
            label="Total outstanding shares"
            dataName="totalSharesOutstanding"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Total fully diluted shares"
            dataName="fullyDilutedShares"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Backsolve equity value as of previous transaction"
            dataName="backsolveEquityValue"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            add$
            addCommas
          />
          <InfoTile
            label="Last transaction fully diluted shares"
            dataName="lriFullyDilutedShares"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            addCommas
          />
          <InfoTile
            label="Equity value post-money as of transaction date"
            dataName="concludedEquityValue"
            isEditing={isEditing}
            values={lastRoundInformation}
            setValues={setLastRoundInformation}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            add$
            addCommas
          />
        </div>
      </div>
      <div className="block-header prev-val">
        <h5>&apos;As of&apos; previous transaction</h5>
      </div>
      <div className="input-block block-four">
        <div className="block-header header-four previous-val">
          {[
            ['Last fiscal year', 'previous trans. LFY ($)'],
            ['Last twelve months', 'previous trans. LTM ($)'],
            ['Current fiscal year', 'previous trans. CFY ($)'],
            ['Next 12 months', 'previous trans. NTM ($)'],
            ['Next fiscal year', 'previous trans. NFY ($)'],
          ].map((label) => (
            <span key={label[0]}>
              {label[0]}
              <br />
              <p>
                {label[1]}
              </p>
            </span>
          ))
          }
        </div>
        <div className="input-tiles fiscal-periods prev-val">
          {['Revenue', 'EBITDA'].map((rowName, idx) => (
            <div key={rowName} className={`indicator-rows${idx % 2 === 0 ? ' even' : ' odd'}`}>
              <span className={`${rowName.toLowerCase()}`}>{rowName}</span>
              {[
                `lastFiscalYear${rowName}`,
                `last12Months${rowName}`,
                `currentFiscalYear${rowName}`,
                `next12Months${rowName}`,
                `nextFiscalYear${rowName}`,
              ].map((dataName) => {
                const [metrics, setMetrics] = useState({});
                useEffect(() => {
                  setLastRoundInformation((prev) => {
                    const newLastRoundInformation = copy(prev);
                    newLastRoundInformation.performanceMetrics = { ...prev.performanceMetrics, ...metrics };
                    return newLastRoundInformation;
                  });
                }, [metrics]);
                return (
                  <InfoTile
                    key={dataName}
                    label=""
                    dataName={dataName}
                    isEditing={isEditing}
                    values={lastRoundInformation?.performanceMetrics || metrics}
                    setValues={setMetrics}
                    setChangeInProgress={setChangeInProgress}
                    setChangeHasBeenMade={setChangeHasBeenMade}
                    add$
                    addCommas
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// ClientInputs.propTypes = {
//   userData: PropTypes.object.isRequired,
//   setUserData: PropTypes.func.isRequired,
//   checkForMissingCompInputs: PropTypes.bool.isRequired,
//   setCheckForMissingCompInputs: PropTypes.func.isRequired,
//   exitingToTab: PropTypes.oneOfType([
//     PropTypes.bool,
//     PropTypes.number,
//   ]).isRequired,
//   setExitingToTab: PropTypes.func.isRequired,
//   setTabSelected: PropTypes.func.isRequired,
// };
