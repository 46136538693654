import React, {
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Button, Dialog, IconButton } from '@mui/material';

import StorefrontIcon from '@mui/icons-material/Storefront';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';

import AddCompanyBlock from './AddCompanyBlock';

import { getUserCompanyId } from '../utils/auth';
import { copy } from '../utils';

import useFetch from '../hooks/useFetch';

import './AddCompanyToProject.scss';
import { AppDataContext } from '../contexts';

export default function AddCompanyToProject({
  addCompany,
  setAddCompany,
  projectId,
  investorCompanyId,
  setCompanies,
  companies,
  enterpriseCompanyIdent,
  numberOfCompanies,
  setNumberOfCompanies,
  investor,
  // portfolioCompaniesNames,
  portfolioCompanies,
}) {
  const {portfolioCompaniesNames} =  useContext(AppDataContext)
  const dialogScrollRef = useRef(null);
  const companyListRef = useRef(null);
  const [companyRowAdded, setCompanyRowAdded] = useState(false);
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [addingCompanies, setAddingCompanies] = useState(false);
  const [blockHasError, setBlockHasError] = useState(false);
  const [companyNameErrorList, setCompanyNameErrorList] = useState([]);
  const [portfolioCompanyData, setPortfolioCompanyData] = useState(portfolioCompanies || {});
  const [portfolioNames, setPortfolioNames] = useState(portfolioCompaniesNames || []);

  const enterpriseCompanyId = investor ? enterpriseCompanyIdent : getUserCompanyId();

  const [, getInvestorCompaniesRequest] = useFetch();

  const [, newCompanyRequest] = useFetch();

  useEffect(() => {
    if (addCompany) {
      getInvestorCompaniesRequest({
        url: '/companies/get_investor_and_portfolio_companies/',
        urlIds: [enterpriseCompanyId, 'userId'],
        onSuccess: (responseData) => {
          setPortfolioCompanyData(responseData.portfolioCompanies);
          setPortfolioNames(Object.keys(responseData.portfolioCompanies));
        },
      });
    }
  }, [addCompany]);

  function updateProjectCompanies() {
    if (!blockHasError) {
      setAddingCompanies(true);
      const updatedCompanies = projectCompanies.map((company) => {
        const companyCopy = { ...company };
        if (portfolioCompanyData[companyCopy.companyName]) {
          companyCopy.companyId = portfolioCompanyData[companyCopy.companyName].companyId;
          companyCopy.capIqIdentifier = portfolioCompanyData[companyCopy.companyName].capIqIdentifier;
        } else {
          companyCopy.companyId = '';
        }
        return companyCopy;
      });
      const projectPortfolioCompanyUpdateData = {
        enterpriseCompanyId,
        investorCompanyId,
        projectId,
        companies: updatedCompanies,
        assignees: {},
      };
      newCompanyRequest({
        url: '/companies/portfolio/new-portfolio-companies', method: 'post',
        body: projectPortfolioCompanyUpdateData, bodyIds: ['requestUserId'],
        onSuccess: (responseData) => {
          const companiesCopy = copy(companies);
          if (!investor) {
            setCompanies([...companiesCopy, ...responseData.transactionCards]);
          } else {
            const newPortfolioCompanies = [...companiesCopy];
            responseData.transactionCards.forEach((company) => {
              const newCompany = {
                companyIndex: newPortfolioCompanies.length,
                companyName: company.portfolioCompanyName,
                portfolioCompanyId: company.portfolioCompanyId,
                transactionId: company.transactionId,
                status: company.status,
                submittedByUser: company.submittedByUser,
                isLocked: company.isLocked,
              };
              newPortfolioCompanies.push(newCompany);
            });
            setCompanies(newPortfolioCompanies);
          }
          setAddCompany(false);
          setProjectCompanies([]);
          setCompanyNameErrorList([]);
          setBlockHasError(false);
          setNumberOfCompanies(numberOfCompanies + projectCompanies.length);
          setAddingCompanies(false);
        },
      });
    }
  }

  function checkCompanyNames() {
    const errorArray = [];
    projectCompanies.forEach((company) => {
      if (company.companyName.length < 1) errorArray.push(true);
      else errorArray.push(false);
    });
    if (errorArray.includes(true)) setBlockHasError(true);
    else {
      setBlockHasError(false);
      updateProjectCompanies();
    }
    setCompanyNameErrorList(errorArray);
  }

  useEffect(() => { if (projectCompanies.every((company) => company.companyName.length)) setBlockHasError(false); }, [projectCompanies]);

  useEffect(() => {
    if (companyRowAdded) {
      setTimeout(() => {
        if (dialogScrollRef.current) {
          dialogScrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
      setTimeout(() => {
        if (companyListRef.current) {
          companyListRef.current.scrollIntoView({ behavior: 'smooth' });
          companyListRef.current.focus();
        }
      }, 200);
    }
    setCompanyRowAdded(false);
  }, [companyRowAdded]);

  return (
    <Dialog
      className="AddCompanyToProject"
      open={addCompany}
      disableScrollLock
    >
      <div className="add-companies-block">
        <div className="header-intro-wrapper">
          <StorefrontIcon />
          <div className="header-text-wrapper">
            <h4>Add companies to this project</h4>
            <p>
              Companies you add here will be added to
              {!investor ? ' both ' : ' '}
              your company list
              {!investor ?
                ' and to the form that was sent to your client.' +
                " They'll be able to input information about these companies as they fill out their form." :
                '.'}
            </p>
          </div>
          <IconButton
            className="close-icon"
            onClick={() => {
              setAddCompany(false);
              setProjectCompanies([]);
              setCompanyNameErrorList([]);
              setBlockHasError(false);
              setAddingCompanies(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {projectCompanies && (
          <div className="company-block-wrapper">
            {projectCompanies.map((company, index) => (
              <AddCompanyBlock
                key={company.companyId}
                projectCompanies={projectCompanies}
                setProjectCompanies={setProjectCompanies}
                company={company}
                index={index}
                setBlockHasError={setBlockHasError}
                companyNameErrorList={companyNameErrorList}
                setCompanyNameErrorList={setCompanyNameErrorList}
                portfolioCompaniesNames={portfolioNames}
                investor={investor}
                setCompanyRowAdded={setCompanyRowAdded}
                companyListRef={companyListRef}
                companiesInProject={companies.map((comp) => !investor ? comp.portfolioCompanyName : comp.companyName)}
              />
            ))}
          </div>
        )}
        <div className="add-company-btn-info-wrapper" ref={dialogScrollRef}>
          <Button
            className="add-company-btn"
            onClick={() => {
              const newCompany = {
                companyId: uuidv4(),
                companyName: '',
                note: '',
                prefillData: 0,
                whiteGlove: 0,
              };
              setProjectCompanies([...projectCompanies, newCompany]);
            }}
          >
            <AddBusinessOutlinedIcon />
            {`${projectCompanies.length > 0 ? 'Add another company' : 'Add a company'}`}
          </Button>
        </div>
        <hr />
        <Button
          className={`add-companies-btn${projectCompanies.length > 0 && !blockHasError ? '' : ' disabled'}`}
          disabled={blockHasError && projectCompanies.length > 0}
          onClick={() => { checkCompanyNames(); }}
        >
          {addingCompanies ? (
            <>
              <div className="dots-circle-spinner" />
              {`Adding ${projectCompanies.length > 1 ? 'companies' : 'company'}...`}
            </>
          ) : (
            <>
              <AddOutlinedIcon />
              {`Add ${projectCompanies.length > 1 ? 'companies' : 'company'}`}
            </>
          )}
        </Button>
      </div>
    </Dialog>
  );
}

AddCompanyToProject.propTypes = {
  addCompany: PropTypes.bool.isRequired,
  setAddCompany: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  investorCompanyId: PropTypes.string,
  setCompanies: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  numberOfCompanies: PropTypes.number,
  setNumberOfCompanies: PropTypes.func,
  enterpriseCompanyIdent: PropTypes.string,
  investor: PropTypes.bool,
  portfolioCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  portfolioCompanies: PropTypes.object,
};

AddCompanyToProject.defaultProps = {
  enterpriseCompanyIdent: '',
  investor: false,
  investorCompanyId: '',
  projectId: '',
  companies: [],
  setNumberOfCompanies: () => { },
  numberOfCompanies: 0,
  setCompanies: () => { },
  portfolioCompaniesNames: [''],
  portfolioCompanies: {},
};
