import React, { useState, useEffect,useContext } from 'react';
import { Dialog, Stepper, Step, StepLabel } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import useFetch from '../hooks/useFetch';
import './FileuploadMoal.scss';
import { AppDataContext, UserContext } from '../contexts';

export default function FileuploadModal(
  { modalupload,
    setmodalupload,
    valuationDate,
    setValuationdatauploaded, 
    filelocation,
  },
) {
  const [progress, setProgress] = useState(0);
  const [, setOptions] = useFetch();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentstep, setCurrentStep] = useState("Uploading model file'");
  const [compsSteps, setCompsSteps] = useState([
    'Uploading model file', // 9 seconds
    'Reading and analyzing model data', // 3seconds
    'Creating new ASC 820', // 2 seconds
    'Adding to database', // 2 seconds
    'Updating Industry & Comps Data', // 25 seconds
    'Finalizing', // rest on this step
  ]);
  const { userData, setUserData } = useContext(UserContext);
  const { currentInvestorId } = useContext(AppDataContext);
  function closemodel() {
    setTimeout(() => {
      setProgress(0);
      setmodalupload(false);
      setValuationdatauploaded(true)
    }, 5000);
  }
  useEffect(() => {
    setCurrentStep(compsSteps[0]);
    setCurrentStepIndex(0);
    if (modalupload === true) {
      setTimeout(() => {
        setCurrentStep(compsSteps[1]);
        setCurrentStepIndex(1);
        setTimeout(() => {
          setCurrentStep(compsSteps[2]);
          setCurrentStepIndex(2);
          setTimeout(() => {
            setCurrentStep(compsSteps[3]);
            setCurrentStepIndex(3);
            setTimeout(() => {
              setCurrentStep(compsSteps[4]);
              setCurrentStepIndex(4);
              setTimeout(() => {
                setCurrentStep(compsSteps[5]);
                setCurrentStepIndex(5);
              }, 25000);
            }, 2000);
          }, 3000);
        }, 3000);
      }, 9000);
    }
  }, [modalupload]);

  useEffect(() => {
    if (modalupload) {
      const enterpriseid = userData.userAttributes.enterpriseCompanyId;
      const queryParams = new URLSearchParams(window.location.search);
      const pId = queryParams.get('pId');
      const requestBody = {
        enterpriseCompanyId: enterpriseid,
        investorCompanyId: currentInvestorId,
        projectId: pId,
        documentType: '820',
        documentLocation: filelocation,
        valuationDate: moment(valuationDate).format('YYYY-MM-DD'),
      };
      const options = {
        method: 'POST',
        url: '/documents/process-rw-820', // Your API endpoint
        body: requestBody,
        onSuccess: ( data ) => {
          closemodel(); // Close modal on successful response
        },
        onError: (error) => {
          closemodel(); // Close modal on error as well
        },
      };
      setOptions(options);
    }
    const duration = 45 * 1000; // 30 seconds in milliseconds
    const increment = 1; // Increment value based on 100ms interval
    const interval = setInterval(() => {
      if (modalupload) {
        setProgress( (prev)=> {
          if (prev >= 100) {
            clearInterval(interval); // Stop the interval when progress reaches 100
            closemodel();
            return 100;
          }
          return prev + increment; // Increment progress
        });
      }
    }, 450); // Update every 100ms
    return () => clearInterval(interval);
  }, [modalupload]);
  return (
    <Dialog
      className="uploadvaluationtimmer"
      open={modalupload}
      disableScrollLock
    >
      <div className="add-companies-block">
        <div className="header-intro-wrapper w-100">
          <div className="header-text-wrapper w-100">
            <div className="run-comps-tool-tip-header">
              <p>{currentstep}</p>
            </div>
            <div className="linear-progress">
              <div className="linear-progress-header">
                <span>Status</span>
                <span className="current-step">{`${progress}%`}</span>
              </div>
              <LinearProgress variant="determinate" value={progress} />
            </div>
            <Stepper activeStep={currentStepIndex} orientation="vertical">
              {compsSteps.map((label) => (
                <Step key={label} className="individual-step">
                  <StepLabel className="steper_label_custom">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
