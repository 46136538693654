import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import CompanyCard from './CompanyCard';
import SortBy from '../../components/SortBy';
import CalcInputs from '../CalcInputs';
import ASC820Dialog from '../../components/ASC820Dialog';
import HoverInput from './components/HoverInput';
import AddCompanyToProject from '../../components/AddCompanyToProject';
import UploadValuationToProject from '../../components/UploadValuationToProject';

import { UserContext, NavWidthContext, SubNavStateContext, AppDataContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import './ProjectCompanies.scss';

function CustomDroppable({ children, ...props }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;

  return <Droppable {...props}>{children}</Droppable>;
}

CustomDroppable.propTypes = { children: PropTypes.func.isRequired };

export default function ProjectCompanies() {
  const { setNavWidth } = useContext(NavWidthContext);
  const { userData, setUserData } = useContext(UserContext);
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const {
    enterpriseUsers,
    projectToView,
    projectCompanies,
    setProjectCompanies,
    transactionToView,
    setTransactionToView,
    setCurrentInvestorId,
    // tabSelected, setTabSelected
  } = useContext(AppDataContext);
  const [sortedOrFilteredCompanies, setSortedOrFilteredCompanies] = useState([]);
  const [totalFee, setTotalFee] = useState(null);
  const [paidToDate, setPaidToDate] = useState(null);
  const [dateInputValues, setDateInputValues] = useState({});
  const [updateAssignee, setUpdateAssignee] = useState(null);
  const [updateHoverInputs, setUpdateHoverInputs] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dataEntryWorkTo, setDataEntryWorkTo] = useState(false);
  const [preparerWorkTo, setPreparerWorkTo] = useState(false);
  const [reviewerWorkTo, setReviewerWorkTo] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [numberOfCompanies, setNumberOfCompanies] = useState(null);

  const [startASC820, setStartASC820] = useState(false);

  const [uploadValuation, setUploadValuation] = useState(false);

  const [addCompany, setAddCompany] = useState(false);
  const [sortBy, setSortBy] = useState('Most recent');
  const [filteredUser, setFilteredUser] = useState('All users');
  const [filteredStatus, setFilteredStatus] = useState('All statuses');
  const sortByList = ['Most recent', 'Alphabetical', 'Info due date', 'Audit date', 'Priority'];
  const [filterByUsers, setFilterByUsers] = useState(['All users']);

  const filterByStatus = [
    'All statuses',
    'Client not started 820',
    'Client in-progress',
    '820 Data entry',
    '820 Analysis',
    '820 Review',
    'Draft in-progress',
    'Draft review',
    'Draft delivered',
    'Draft iteration',
    'Draft audit',
    'Financial statements ready',
  ];
  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current)
      window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    }
  }, []);

  const [{ loading: companiesLoading }, list820sRequest] = useFetch();

  async function getProjectCompanies() {
    const urlParams = new URLSearchParams(window.location.search);
    list820sRequest({
      url: '/transactions/asc820/list-820s/',
      urlIds: [
        'enterpriseCompanyId',
        projectToView?.investorCompanyId || urlParams.get('iId'),
        projectToView?.projectId,
        'userId',
      ],
      onSuccess: (responseData) => {
        
        setFilterByUsers([
          ...filterByUsers,
          ...enterpriseUsers.map((user) => `${user.firstName} ${user.lastName}`),
        ]);
        setProjectCompanies(responseData);
        setNumberOfCompanies(responseData.length);
      },
    });
  }

  const [, getprojectToView] = useFetch();

  const [, setProjectDetails] = useState({});

  async function getProjectDetails() {
    getprojectToView({
      url: '/projects/asc820/get-project-details/',
      urlIds: [
        'enterpriseCompanyId',
        'investorCompanyId',
        projectToView?.projectId,
        'requestUserId',
      ],
      onSuccess: (responseData) => {
        setCurrentInvestorId(responseData.investorCompanyId)
        setProjectDetails(responseData);
        setTotalFee(responseData.totalFee);
        setPaidToDate(responseData?.paidToDate);
      },
    });
  }

  function parseOutDates() {
    const dates = {
      clientInfoDueDate: projectToView?.clientInfoDueDate,
      auditDate: projectToView?.auditDate,
      draftDueDate: projectToView?.draftDueDate,
      publishFinancialsDate: projectToView?.publishFinancialsDate,
    };
    setDateInputValues(dates);
  }

  function getCookie(name) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return JSON.parse(cookie.substring(cookieName.length, cookie.length));
      }
    }
    return null;
  }

  function setCookie(name, value, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    const stringValue = JSON.stringify(value);
    document.cookie = `${name}=${stringValue};${expires};path=/`;
  }

  function handleSave(reorderedCompanies = sortedOrFilteredCompanies) {
    setIsSaving(true);
    const transactionOrderMap = {};
    for (let i = 0; i < reorderedCompanies.length; i += 1) {
      const transaction = reorderedCompanies[i];
      transactionOrderMap[transaction.transactionId] = i;
    }
    setCookie('transactionOrder', transactionOrderMap, 7);
    setTimeout(() => {
      setIsSaving(false);
    }, 1500);
  }

  useEffect(() => {
    console.log(typeof projectCompanies, 'projectCompanies.type');
    console.log(projectCompanies, 'Project Componies');

    if (projectCompanies) {
      parseOutDates();
    }
  }, [projectCompanies]);

  useEffect(() => {
    getProjectCompanies();
    console.log(getProjectDetails());
    getProjectDetails();
  }, []);

  useEffect(() => {
    if (!companiesLoading && projectCompanies.length > 0) {
      let projectCompaniesSorted = projectCompanies?.sort((a, b) =>
        a.portfolioCompanyName.localeCompare(b.portfolioCompanyName),
      );
      const savedOrder = getCookie('transactionOrder');
      /* eslint-disable */
      if (sortBy === 'Alphabetical')
        projectCompaniesSorted = projectCompanies?.sort((a, b) =>
          a.portfolioCompanyName.localeCompare(b.portfolioCompanyName),
        );
      if (sortBy === 'Info due date')
        projectCompaniesSorted = projectCompanies?.sort(
          (a, b) => new Date(a.infoDueDate) - new Date(b.infoDueDate),
        );
      if (sortBy === 'Audit date')
        projectCompaniesSorted = projectCompanies?.sort(
          (a, b) => new Date(a.auditDate) - new Date(b.auditDate),
        );
      if (sortBy === 'Priority')
        projectCompaniesSorted = projectCompanies?.sort((a, b) => a.priority - b.priority);

      if (filteredUser !== 'All users') {
        projectCompaniesSorted = projectCompaniesSorted.filter((company) => {
          const dataEntryAssignment = enterpriseUsers.find(
            (u) => u.accountId === company.assignees.dataEntry.accountId,
          );
          const preparerAssignment = enterpriseUsers.find(
            (u) => u.accountId === company.assignees.preparer.accountId,
          );
          const reviewerAssignment = enterpriseUsers.find(
            (u) => u.accountId === company.assignees.reviewer.accountId,
          );
          if (dataEntryAssignment) {
            if (
              `${dataEntryAssignment.firstName.toLowerCase()} ${dataEntryAssignment.lastName.toLowerCase()}` ===
              filteredUser.toLowerCase()
            )
              return true;
          }
          if (preparerAssignment) {
            if (
              `${preparerAssignment.firstName.toLowerCase()} ${preparerAssignment.lastName.toLowerCase()}` ===
              filteredUser.toLowerCase()
            )
              return true;
          }
          if (reviewerAssignment) {
            if (
              `${reviewerAssignment.firstName.toLowerCase()} ${reviewerAssignment.lastName.toLowerCase()}` ===
              filteredUser.toLowerCase()
            )
              return true;
          }
          return false;
        });
      }
      /* eslint-enable */

      if (filteredStatus !== 'All statuses') {
        projectCompaniesSorted = projectCompaniesSorted.filter(
          (company) => company.status.toLowerCase() === filteredStatus.toLowerCase(),
        );
      }
      if (savedOrder && projectCompanies) {
        projectCompaniesSorted = projectCompaniesSorted.sort((a, b) => {
          const orderA = savedOrder[a.transactionId];
          const orderB = savedOrder[b.transactionId];
          return orderA - orderB;
        });
        setSortedOrFilteredCompanies(projectCompaniesSorted);
      }
      setSortedOrFilteredCompanies(projectCompaniesSorted);
    }
  }, [companiesLoading, sortBy, filteredUser, filteredStatus]);

  useEffect(() => {
    if (subNavState.assigneeData) {
      setSortedOrFilteredCompanies((prevCompanies) => {
        const updatedCompanies = [...prevCompanies];
        const updatedCompany = updatedCompanies.find(
          (company) => company.transactionId === subNavState.assigneeData.transactionId,
        );
        updatedCompany.assignees = {
          ...updatedCompany.assignees,
          [subNavState.assigneeData.role]: {
            accountId: subNavState.assigneeData.assigneeUserId,
            email: enterpriseUsers.find(
              (user) => user.accountId === subNavState.assigneeData.assigneeUserId,
            ).email,
          },
        };
        return updatedCompanies;
      });
      setSubNavState((prevState) => {
        const stateCopy = { ...prevState };
        delete stateCopy.assigneeData;
        return stateCopy;
      });
    }
  }, [subNavState]);

  useEffect(() => {
    if (projectCompanies.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const transactionId = urlParams.get('tId');
      const projectCompanyData = projectCompanies?.find(
        (company) => company.transactionId === transactionId,
      );
      if (transactionId && projectCompanyData) {
        setTransactionToView(projectCompanyData);
        setUserData({
          ...userData,
          metaData: {
            ...projectCompanyData,
            // investorFirmName: projectCompaniesData.investorFirmName,
            // primaryAccountId: projectCompaniesData.primaryAccountId,
             investorFirmName: projectToView.investorFirmName,
            primaryAccountId: projectToView.primaryAccountId,
          },
        });
      }
    }
  }, [projectCompanies]);

  function handleDragDrop(result) {
    const { source, destination } = result;
    if (!destination) return;
    setSortedOrFilteredCompanies((prevCompanies) => {
      const reorderedCompanies = [...prevCompanies];
      const [removedCompany] = reorderedCompanies.splice(source.index, 1);
      reorderedCompanies.splice(destination.index, 0, removedCompany);
      setProjectCompanies(reorderedCompanies);
      console.log('reorderedCompanies,', reorderedCompanies);
      handleSave(reorderedCompanies);
      return reorderedCompanies;
    });
  }

  const [{ callEnded: updateCardEnded }, updateCardRequest] = useFetch();
  const [{ callEnded: projectCardEnded }, updateProjectRequest] = useFetch();

  async function updateProjectDates() {
    const bodyIds = ['enterpriseCompanyId', 'requestUserId'];
    const updateCardData = {
      payload: {
        ...dateInputValues,
      },
      projectId: projectToView.projectId,
    };
    updateCardRequest({
      url: '/projects/asc820/update-project-card',
      method: 'post',
      body: updateCardData,
      bodyIds,
    });
    const updateProjectData = {
      payload: {
        ...dateInputValues,
        paidToDate,
        totalFee,
      },
      projectId: projectToView.projectId,
    };
    updateProjectRequest({
      url: '/projects/asc820/update-project',
      method: 'post',
      body: updateProjectData,
      bodyIds,
    });
  }

  useEffect(() => {
    if (updateCardEnded && projectCardEnded) setUpdateHoverInputs(false);
  }, [updateCardEnded, projectCardEnded]);

  const [, updateAllTransactionsRolesRequest] = useFetch();

  async function updateAssigneeAcrossTransactions(assignee) {
    const { accountId, role } = assignee;
    const { investorCompanyId } = sortedOrFilteredCompanies[0];
    const updateAllTransactions = {
      investorCompanyId,
      assigneeUserId: accountId,
      role,
      projectId: projectToView?.projectId,
    };
    updateAllTransactionsRolesRequest({
      url: '/transactions/asc820/update-role/all-transactions-in-project',
      method: 'post',
      body: updateAllTransactions,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
    });
  }

  useEffect(() => {
    const updatedProjectCompanies = sortedOrFilteredCompanies.map((company) => {
      const updatedCompany = { ...company };
      if (updateAssignee) {
        if (updateAssignee.role === 'dataEntry') {
          updatedCompany.assignees = { ...updatedCompany.assignees, dataEntry: updateAssignee };
        }
        if (updateAssignee.role === 'preparer') {
          updatedCompany.assignees = { ...updatedCompany.assignees, preparer: updateAssignee };
        }
        if (updateAssignee.role === 'reviewer') {
          updatedCompany.assignees = { ...updatedCompany.assignees, reviewer: updateAssignee };
        }
      }
      return updatedCompany;
    });
    setSortedOrFilteredCompanies(updatedProjectCompanies); // Update the state
    if (updateAssignee) updateAssigneeAcrossTransactions(updateAssignee);
  }, [updateAssignee]);

  useEffect(() => {
    if (updateHoverInputs) updateProjectDates();
  }, [updateHoverInputs]);

  if (transactionToView && Object.keys(transactionToView).length > 0) {
    return (
      <CalcInputs
        // enterpriseUsers={enterpriseUsers}
        // transactionToView={transactionToView}
        // tabSelected={tabSelected}
        // setTabSelected={setTabSelected}
      />
    );
  }

  return (
    <>
      <main className='ProjectCompanies' ref={appWidthRef}>
        <div className='util-header'>
          <div className='util-controls'>
            <div className='anc-company-info'>
              <div className='hover-inputs flex-end'>
                <span className='total-companies'>
                  Total companies:&nbsp;
                  {numberOfCompanies}
                </span>
                <HoverInput
                  setInputValues={setTotalFee}
                  inputValues={totalFee}
                  setUpdateHoverInputs={setUpdateHoverInputs}
                  label='Total fees'
                  add$
                  addCommas
                />
                <HoverInput
                  setInputValues={setPaidToDate}
                  inputValues={paidToDate}
                  setUpdateHoverInputs={setUpdateHoverInputs}
                  label='Paid to-date'
                  add$
                  addCommas
                />
              </div>
            </div>
            <Button className='save-run-btns save' onClick={() => handleSave()}>
              {isSaving ? (
                <>
                  <div className='dots-circle-spinner' />
                  <p className='save-run-btns saving'>Saving</p>
                </>
              ) : (
                <>
                  <SaveOutlinedIcon />
                  Save
                </>
              )}
            </Button>
          </div>
          <div className='project-companies-header'>
            <p>{projectToView?.investorFirmName}</p>
            <span>
              820 |&nbsp;
              {projectToView?.createdDate}
            </span>
          </div>
          <hr className='header-spacer' />
          <div className='hover-inputs'>
            <HoverInput
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateHoverInputs={setUpdateHoverInputs}
              label='Client data due'
              dbKey='clientInfoDueDate'
              isDate
            />
            <HoverInput
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateHoverInputs={setUpdateHoverInputs}
              label='Draft due'
              dbKey='draftDueDate'
              isDate
            />
            <HoverInput
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateHoverInputs={setUpdateHoverInputs}
              label='Audit start'
              dbKey='auditDate'
              isDate
            />
          </div>
          <div className='add-company-btn'>
            <Button
              onClick={() => {
                setUploadValuation(true);
                // console.log(userData);
              }}
            >
              <PublishOutlinedIcon />
              Upload Valuation
            </Button>
            <Button onClick={() => setStartASC820(true)}>
              <SendOutlinedIcon />
              Send form to client
            </Button>
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              <AssignmentIndOutlinedIcon />
              Assign all
            </Button>
            <Menu
              id='assign-all-menu'
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => {
                setAnchorEl(null);
                setTimeout(() => {
                  setAnchorEl(false);
                }, 200);
                setDataEntryWorkTo(false);
                setPreparerWorkTo(false);
                setReviewerWorkTo(false);
              }}
              disableScrollLock
            >
              {[
                ['Data entry work to', dataEntryWorkTo, setDataEntryWorkTo],
                ['Preparer work to', preparerWorkTo, setPreparerWorkTo],
                ['Reviewer work to', reviewerWorkTo, setReviewerWorkTo],
              ].map((role) => (
                <Tooltip
                  key={role[0]}
                  id='all-user-menu'
                  open={role[1]}
                  placement='right'
                  arrow
                  title={
                    <>
                      {enterpriseUsers.map((user) => (
                        <Button
                          key={user.accountId}
                          onClick={(e) => {
                            e.stopPropagation();
                            role[2](false);
                            setAnchorEl(null);
                            setUpdateAssignee({
                              accountId: user.accountId,
                              email: user.email,
                              role:
                                role[0] === 'Data entry work to'
                                  ? 'dataEntry'
                                  : role[0] === 'Preparer work to'
                                  ? 'preparer'
                                  : 'reviewer',
                            });
                          }}
                        >
                          {user.firstName} {user.lastName}
                        </Button>
                      ))}
                    </>
                  }
                >
                  <MenuItem
                    className={
                      role[0] === 'Data entry work to'
                        ? dataEntryWorkTo
                          ? 'active'
                          : ''
                        : role[0] === 'Preparer work to'
                        ? preparerWorkTo
                          ? 'active'
                          : ''
                        : reviewerWorkTo
                        ? 'active'
                        : ''
                    }
                    onClick={() => {
                      role[2](true);
                      if (reviewerWorkTo) setReviewerWorkTo(!reviewerWorkTo);
                      if (preparerWorkTo) setPreparerWorkTo(!preparerWorkTo);
                      if (dataEntryWorkTo) setDataEntryWorkTo(!dataEntryWorkTo);
                    }}
                  >
                    {role[0]}
                  </MenuItem>
                </Tooltip>
              ))}
            </Menu>
            <Button onClick={() => setAddCompany(true)}>
              <AddOutlinedIcon />
              Add company
            </Button>
          </div>
          <div className='companies-list-header'>
            <h3>
              <StoreOutlinedIcon />
              Company list
            </h3>
          </div>
          <div className='sort-by-select'>
            <SortBy
              sortBy={filteredStatus}
              setSortBy={setFilteredStatus}
              sortByList={filterByStatus}
              type='Filter by status'
            />
            <SortBy
              sortBy={filteredUser}
              setSortBy={setFilteredUser}
              sortByList={filterByUsers}
              type='Filter by user'
            />
            <SortBy sortBy={sortBy} setSortBy={setSortBy} sortByList={sortByList} type='Sort by' />
          </div>
        </div>
        <div className='list-of-companies'>
          <div className='project-list-wrapper'>
            {companiesLoading ? (
              <div className='dots-circle-spinner' />
            ) : (
              <DragDropContext
                onDragEnd={(result) => {
                  handleDragDrop(result);
                }}
              >
                <CustomDroppable droppableId='ROOT' type='group'>
                  {(provided) => (
                    // eslint-disable-next-line
                    <div
                      className='wrapper-of-draggable'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {sortedOrFilteredCompanies.map((company, index) => (
                        <CompanyCard
                          key={company.transactionId}
                          company={company}
                          index={index}
                          enterpriseUsers={enterpriseUsers}
                          setProjectCompanies={setSortedOrFilteredCompanies}
                          projectCompanies={sortedOrFilteredCompanies}
                          updateAssignee={updateAssignee}
                          setUpdateAssignee={setUpdateAssignee}
                          investorFirmName={projectToView.investorFirmName}
                          primaryInvestorId={projectToView.primaryAccountId}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </CustomDroppable>
              </DragDropContext>
            )}
          </div>
        </div>
      </main>
      <AddCompanyToProject
        addCompany={addCompany}
        setAddCompany={setAddCompany}
        projectId={projectToView.projectId}
        investorCompanyId={projectToView.investorCompanyId}
        setCompanies={setSortedOrFilteredCompanies}
        companies={sortedOrFilteredCompanies}
        numberOfCompanies={projectCompanies.length}
        setNumberOfCompanies={setNumberOfCompanies}
        
      />
      <ASC820Dialog
        startASC820={startASC820}
        setStartASC820={setStartASC820}
        projectToView={projectToView}
        emailOnly
      />
      <UploadValuationToProject
        uploadValuation={uploadValuation}
        setUploadValuation={setUploadValuation}
        projectToView={projectToView}
      />
    </>
  );
}

ProjectCompanies.propTypes = {
  enterpriseUsers: PropTypes.arrayOf(PropTypes.object),
  // projectToView: PropTypes.object.isRequired,
  portfolioCompaniesNames: PropTypes.array,
  portfolioCompanies: PropTypes.array,
  portfolioCompaniesList: PropTypes.object,
  investorCompaniesList: PropTypes.object,
  investorCompaniesNames: PropTypes.array,
  tabSelected: PropTypes.string,
  // setTabSelected: PropTypes.func.isRequired,
  transactionToView: PropTypes.object,
  // setTransactionToView: PropTypes.func.isRequired,
  projectCompanies: PropTypes.array,
  // setProjectCompanies: PropTypes.func.isRequired,
};

ProjectCompanies.defaultProps = {
  enterpriseUsers: [],
  portfolioCompaniesNames: [],
  portfolioCompanies: [],
  portfolioCompaniesList: {},
  investorCompaniesList: {},
  investorCompaniesNames: [],
  transactionToView: {},
  tabSelected: '',
  projectCompanies: [],
};
